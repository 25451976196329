import Vue from 'vue'
import App from '@/App.vue'

Vue.config.productionTip = false

import axios from "axios"
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import router from '@/router.js'
import store from '@/store.js'

import Amplify from 'aws-amplify'
import awsmobile from './aws-exports'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'

import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'

import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui'
I18n.putVocabularies(translations)
I18n.setLanguage('ja')

Amplify.configure(awsmobile)

applyPolyfills().then(() => {
  defineCustomElements(window)
})

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    mandatorySignIn: false
  },
  Storage: {
    AWSS3: {
      bucket: 'creativesdisplay01322-dev',
      region: 'ap-northeast-1',
    }
  }
})

Vue.config.ignoredElements = [/amplify-\w*/]

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

