<template>
  <v-app>
    <v-main>
      <v-container class="mt-10" style="text-align: center;">
      <v-row>
        <v-col>
          <router-link :to="{ name: 'AddTarget'}">
            <v-card
              class="mx-auto"
              max-width="450"
              height="200"
            >
              <v-app-bar
                dark
                color="orange"
              >

                <v-toolbar-title style="color: black;">ブランドを追加</v-toolbar-title>

              </v-app-bar>

              <v-container>
                新しくブランドを追加する。クライアントのGoogle AnalysisのビューIDが必要。
              </v-container>
            </v-card>
          </router-link>
        </v-col>

        <v-col>
          <router-link :to="{ name: 'AddAccount'}">
            <v-card
              class="mx-auto"
              max-width="450"
              height="200"
            >
              <v-app-bar
                dark
                color="pink"
              >

                <v-toolbar-title style="color: black;">ユナイテッドデータのアカウントを追加</v-toolbar-title>

              </v-app-bar>

              <v-container>
                ユナイテッドデータ用にユーザーを作った後に登録する
              </v-container>
            </v-card>
          </router-link>
        </v-col>

      </v-row>

        <!-- <v-col>
          <router-link :to="{ name: 'AddCampaign'}">
            <v-card
              class="mx-auto"
            >
              <v-app-bar
                dark
                color="#aaa"
              >
                <v-toolbar-title style="color: black;">キャンペーンを追加</v-toolbar-title>
              </v-app-bar>

              <v-container>
                新しくキャンペーンを追加
              </v-container>
            </v-card>
          </router-link>
        </v-col> -->

        <!-- <v-col>
          <router-link :to="{ name: 'Budget'}">
            <v-card
              class="mx-auto"
            >
              <v-app-bar
                dark
                color="#aaa"
              >

                <v-toolbar-title style="color: black;">予算を入力</v-toolbar-title>

              </v-app-bar>

              <v-container>
                キャンペーンの予算を入力する
              </v-container>
            </v-card>
          </router-link>
        </v-col> -->

        <!-- <v-col>
          <router-link :to="{ name: 'UploadCreative'}">
            <v-card
              class="mx-auto"
            >
              <v-app-bar
                dark
                color="#aaa"
              >

                <v-toolbar-title style="color: black;">クリエイティブアップロード</v-toolbar-title>

              </v-app-bar>

              <v-container>
                キャンペーンの予算を入力する
              </v-container>
            </v-card>
          </router-link>
        </v-col> -->
  
        <!-- <v-col>
          <router-link :to="{ name: 'TvIdGenerate'}">
            <v-card
              class="mx-auto"
            >
              <v-app-bar
                dark
                color="#aaa"
              >

                <v-toolbar-title style="color: black;">テレビ動画登録</v-toolbar-title>

              </v-app-bar>

              <v-container>
                テレビ動画を登録しIDを生成する
              </v-container>
            </v-card>
          </router-link>
        </v-col> -->

        <!-- <v-col>
          <router-link :to="{ name: 'EndCampaign'}">
            <v-card
              class="mx-auto"
            >
              <v-app-bar
                dark
                color="#aaa"
              >

                <v-toolbar-title style="color: black;">キャンペーン終了・再開</v-toolbar-title>

              </v-app-bar>

              <v-container>
                キャンペーンを終了・再開する
              </v-container>
            </v-card>
          </router-link>
        </v-col> -->

      </v-container>

    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "InputSelect",
}
</script>