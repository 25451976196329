<template>
    <div>
        <v-icon v-if="eval=='great'" dense color='#bf0017'>mdi-circle-double</v-icon>
        <v-icon v-if="eval=='good'" dense color='#ec6d88'>mdi-circle-outline</v-icon>
        <v-icon v-if="eval=='normal'" dense color="green darken-4">mdi-triangle-outline</v-icon>
        <v-icon v-if="eval=='bad'" dense color="blue darken-4">mdi-close</v-icon>
        <v-icon v-if="eval==''">-</v-icon>
    </div>
</template>

<script>
  export default {
    name: 'EvalIconDense',
    props: ['eval'],
  }
</script>