<template>
  <v-container fluid>
    <v-card
      class="mx-auto pb-4"
      height="100%"
      elevation="6"
    >

    <v-overlay
      v-if="overlay"
      absolute
      opacity="0.1"
    >
      <v-progress-circular
          indeterminate
          color="#ec6d88"
      />
    </v-overlay>

    <Toolbar :title="pageTitle" :jsonData="jsonData" :csvTitle="csvTitle" />

    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-data-table
                :headers="headers"
                :items="this.tableData()"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:item.eval="{ item }">
                  <EvalIconDense :eval="item.eval" />
                </template>
              </v-data-table>
            </v-col>

            <v-col  cols="6">
              <v-row>
                <v-col>
                    <v-card>
                      <v-card-title>局系列ごとの
                       <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              セッション効率
                            </span>
                          </template>
                          <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
                        </v-tooltip>
                      </v-card-title>
                      <AnalysisBarChart
                        :barData="barData()"
                        :labels="stationListSorted"
                        :height="200"
                      />
                    </v-card>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col cols="8">
                        <v-card>
                          <v-card-title>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                セッション効率
                              </span>
                            </template>
                            <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
                          </v-tooltip>
                            の分布</v-card-title>
                          <AnalysisHistogramChart
                            :histogramData="histogramData()"
                            :startValue="startValue"
                            :height="175"
                          />
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card
                          height="100%"
                          justify="center"
                        >
                          <v-card-title>放映回数</v-card-title>
                          <AnalysisPieChart
                            :pieData="pieData()"
                            :labels="stationListSorted"
                            :height="140"
                            :styles="{'display':'flex', 'justify-content':'center'}"
                          />
                        </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    </v-card>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>CM一覧</v-card-title>
            <v-data-table
              :headers="headersAllData"
              :items="this.$store.getters.fullData"
              :items-per-page="15"
              class="elevation-1"
              :sort-by="['station']"
              multi-sort
              dense
            />
          </v-card>
        </v-col>

        <v-col cols="4">
          <SelectWidget />
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import EvalIconDense from '@/components/common/modules/generals/EvalIconDense.vue'
import Toolbar from '@/components/common/modules/generals/Toolbar.vue'
import AnalysisBarChart from '@/components/common/modules/analysis/charts/AnalysisBarChart.vue'
import SelectWidget from "@/components/common/modules/widgets/SelectWidget.vue"
import AnalysisPieChart from '@/components/common/modules/analysis/charts/AnalysisPieChart.vue'
import AnalysisHistogramChart from '@/components/common/modules/analysis/charts/AnalysisHistogramChart.vue'

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default({
  name: "StationAnalysis",
  components: {
    EvalIconDense,
    AnalysisBarChart,
    AnalysisPieChart,
    AnalysisHistogramChart,
    Toolbar,
    SelectWidget
  },
  data(){
    return{
      pageTitle: '局系列',
      overlay: true,
      effectDict: {},
      stationListSorted: [],
      station: '',
      jsonData: [],
      csvTitle: "station",
      headers: [
        { text: '局系列', align: 'start', value: 'station'},
        { text: '放映回数', value: 'count'},
        { text: 'セッション効率', value: 'effect'},
        { text: '評価', value: 'eval'},
      ],
      headersAllData: [
        { text: '局系列', align: 'start',  value: 'station' },
        { text: '日時', value: 'date' },
        { text: '地域', value: 'region' },
        { text: '番組名', value: 'program' },
        { text: 'クリエイティブ', value: 'creative' },
        { text: '点数', value: 'effect' },
        { text: 'GRP', value: 'grp' },
        { text: '放映時刻', value: 'time' },
        // { text: '枠区分', value: 'slot' },
        // { text: 'カップリング', value: 'coupling' },
      ],
      startValue: -15,
    }
  },
  mixins: [calceffect, evaluation],
  methods: {
    barData() {
        const barData = []
        for (let station of this.stationListSorted) {
            barData.push(this.effectDict[station]['effect'])
        }
        return barData
    },
    pieData() {
      const pieData = []
      for (let station of this.stationListSorted) {
          pieData.push(this.effectDict[station]['count'])
      }
      return pieData
    },
    histogramData() {
      // startValue〜endValueまで1刻みのヒストグラムを作成する
      const startValue = -15
      const endValue = 30
      let histArray = new Array(endValue - startValue).fill(0)
      const effectList = this.$store.getters.fullData.map(obj => obj.effect)
      for (let effect of effectList) {
        let index = Math.round(effect) - startValue - 1
        // 外れ値は丸める
        if (index < 0) {
          index = 0
        } else if (index >= histArray.length) {
          index = histArray.length - 1
        }
        histArray[index] += 1
      }
      return histArray
    },
    tableData (){
      // v-data-tableに渡すデータ
      const tableData = []
      for (let key of this.stationListSorted) {
        tableData.push({
          station: key,
          effect: this.effectDict[key]['effect'],
          count: this.effectDict[key]['count'],
          eval: this.evaluation(this.effectDict[key]['effect'])
        })
      }
      return tableData
    },
    createStationListSorted() {
        // セッション効率について降順で並べる
        this.stationListSorted = Object.keys(this.effectDict)
        this.stationListSorted.sort((a, b) => {
            return this.effectDict[b]['effect'] - this.effectDict[a]['effect']
        })
        this.station = this.stationListSorted[0]
    },
    //CSV出力用にデータを整形
    makeEffectJSON(){
      this.jsonData = []
      for (let key of Object.keys(this.effectDict)){
        this.jsonData.push({
          "局系列": key,
          "セッション効率": this.effectDict[key]['effect'],
          "放映回数": this.effectDict[key]['count']
        })
      }
    },
    init() {
      const fullData = this.$store.getters.fullData
      const stationFullList = fullData.map(obj => obj.station)
      const effectFullList = fullData.map(obj => obj.effect)
      this.effectDict = this.calceffect(stationFullList, effectFullList)
      this.createStationListSorted()
      this.overlay = false
      this.makeEffectJSON()  //CSV用のデータ整形
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
})
</script>


<style scoped>
table {
  border-collapse: collapse;
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  color: #470000;
  text-align: center;
}

table tr {
  background-color: #fff;
  padding: .35em;
  border-bottom: 1px dotted #ec6d88;
}
table tr:last-child{
   border-bottom: 2px solid #bf0017;
}
table th,
table td {
  padding: .7em;
  min-width: 3em;
}
tbody th {
    color: #ec6d88;
}
thead th {
  font-size: .8em;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
    width:100%
  }
  table th{
    display: block;
    border-right: none;
    border-bottom: 2px solid #ec6d88;
    padding-bottom: .6em;
    margin-bottom: .6em;

  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    display: block;
    margin-bottom: 2em;
        border-bottom: 2px solid #ec6d88;
  }
  
  table td {
    border-bottom: 1px solid #bbb;
    display: block;
    font-size: .8em;
    text-align: right;
    position: relative;
    padding: .625em .625em .625em 4em;
    border-right: none;
  }
  
  table td::before {
    content: attr(data-label);
    font-weight: bold;
    position: absolute;
    left: 10px;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

</style>