<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'AnalysisBarChart',
  props: [
    'labels', 'barData', 'selectedLabel'
  ],
  data () {
    return {
      rgbaGray: 'rgba(100, 100, 100, 0.3)',
      colorListLong: ['rgba(3,4,94,0.5)', 'rgba(2,62,138,0.5)', 'rgba(0,119,182,0.5)', 'rgba(0,150,199,0.5)', 'rgba(0,180,216,0.5)', 'rgba(72,202,228,0.5)', 'rgba(114,224,239,0.5)', 'rgba(173,232,244,0.5)',  'rgba(202,240,248,0.5)'],
      colorListShort: ['rgba(2,62,138,0.5)', 'rgba(0,150,199,0.5)', 'rgba(72,202,228,0.5)', 'rgba(173,232,244,0.5)',  'rgba(202,240,248,0.5)', 'rgba(100, 100, 100, 0.3)'],
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ]
      },
      options: {
        responive: false,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 0,
                bottom: -25,
            }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
            },
            gridLines: {
              display: false,
            },
            categoryPercentage: 0.75,
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'セッション効率',
            },
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            }
          }]
        }
      }
    }
  },
  methods: {
    showGraph() {
      // 背景色を指定する
      // グラデーションを表示するが、ラベルの数でグラデーションの配列を場合分けする
      const longLength = this.colorListLong.length
      const shortLength = this.colorListShort.length
      const labelLength = this.labels.length
      if (labelLength > shortLength) {
        for (let i=0; i<labelLength; i++) {
          if (i < longLength) {
            this.data.datasets[0].backgroundColor.push(this.colorListLong[i])
          } else {
            this.data.datasets[0].backgroundColor.push(this.rgbaGray)
          }
        }
      } else {
        for (let i=0; i<labelLength; i++) {
          this.data.datasets[0].backgroundColor.push(this.colorListShort[i])
        }
      }
      // データとラベルを代入する
      this.data.datasets[0].data = this.barData
      this.data.labels = this.labels
      this.renderChart(this.data, this.options)
    },
  },
  mounted () {
    this.showGraph()
  },
  // 選択されたラベルが変更されたらグラフを再描画する
  watch: {
    barData: function() {
      this.showGraph()
    }
  }
}
</script>