<template>
  <div>
    <Drawer />

    <v-main>

      <v-toolbar
        flat
        color="#f5f5f5"
      >
      <v-tooltip bottom v-if="ifDashboard()">
        <template v-slot:activator="{ on, attrs }">

          <v-btn
            v-if="ifAdmin()"
            icon
            :to="{name: 'AdminAnalysisDashboard'}"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-view-dashboard</v-icon>
          </v-btn>

          <v-btn
            v-else
            icon
            :to="{name: 'AnalysisDashboard'}"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-view-dashboard</v-icon>
          </v-btn>

        </template>
        <span>ダッシュボードに戻る</span>
      </v-tooltip>
      <v-breadcrumbs
        :items="breadcrumbItems()"
      />
      </v-toolbar>

      <v-container fluid v-if="this.$store.getters.ifExtracted">
        <v-card
          class="mx-auto mt-2"
          max-height="70px"
          color="#efefef"
          elevation="5"
        >
          <v-row>
            <v-col>
              <p class="header-title">選択されたデータ</p>
              <span 
                v-for="theme in Object.keys(this.$store.getters.selectedThemeDict)" 
                :key="theme"
              >{{ theme }}:
              <span 
                v-for="content in themeContent(theme)" 
                :key="content"
                class="font-weight-bold" 
                style="font-size: 1.5em;"
              >
              {{ cleanContent(content) }}</span>
              </span>
            </v-col>
            <v-spacer/>
            <v-col>
              <v-btn
                class="mt-2 ml-6"
                @click="removeExtract()"
              >全体の結果へ戻る
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <router-view v-if="!ifEmpty()" />
      <div
        v-else
        class="text-h5 font-weight-bold mt-10"
      >
        データがありません
      </div>
      
    </v-main>
  </div>
</template>

<script>
import Drawer from "@/components/common/globals/Drawer.vue"
import { campaignformat } from "@/components/mixins/campaignformat"

export default {
  name: "AdminAnalysisIndex",
  components: {
    Drawer,
  },
  mixins: [campaignformat],
  data() {
    return {
      overlay: false,
    }
  },
  methods: {
    removeExtract() {
      this.$store.commit('setFullData', this.$store.getters.fullDataArchive)
      this.$store.commit('setIfExtracted', false)
    },
    cleanContent(x) {
      if (x == '近畿') {
        return '関西'
      } else {
        return x
      }
    },
    breadcrumbItems() {
      let items
      if (this.$store.getters.admin) {
        items = [
          {
            text: this.$store.getters.client,
          },
          {
            text: this.$store.getters.target,
          },
          {
            text: this.textCampaignList(),
          },
        ]
      } else {
        items = [
          {
            text: this.$store.getters.target,
          },
          {
            text: this.textCampaignList(),
          },
        ]
      }
      return items
    },
    textCampaignList() {
      let text = ''
      for (let campaign of this.$store.getters.selectedCampaignList) {
        text = text + this.campaignformat(campaign) + '  &  '
      }
      text = text.slice(0, -3)  // スペースを消去
      return text
    },
    ifDashboard() {
      return !this.$route.path.match(/dashboard/)
    },
    ifAdmin() {
      return this.$store.getters.admin
    },
    ifEmpty() {
      // データが存在するかどうか
      return !this.$store.getters.fullData.length
    },
    themeContent(theme) {
      return this.$store.getters.selectedThemeDict[theme]
    },
  },
  created(){
  },
}
</script>

<style scoped>
.header-title {
  font-size: .7em;
  margin-left: 0;
  margin-bottom: 0;
}
</style>