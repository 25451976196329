<template>
  <v-container fluid>
    <v-card
        elevation="2"
        class="mb-4"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>

      <Toolbar :title="pageTitle" :jsonData="jsonData" :csvTitle="csvTitle" />

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-card>
                  <v-data-table
                    :headers="headers"
                    :items="this.tableData()"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:item.eval="{ item }">
                      <EvalIconDense :eval="item.eval" />
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>

              <v-col  cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title>地域ごとの
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                セッション効率
                              </span>
                            </template>
                            <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
                          </v-tooltip>
                      </v-card-title>
                      <AnalysisBarChart
                        :barData="barData()"
                        :labels="regionListSortedCleaned()"
                        :height="250"
                      />
                    </v-card>
                  </v-col>
                    
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-card>
                          <v-card-title>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  セッション効率
                                </span>
                              </template>
                              <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
                            </v-tooltip>
                            の分布
                          </v-card-title>
                          <AnalysisHistogramChart
                            :histogramData="histogramData()"
                            :startValue="startValue"
                            :height="175"
                          />
                        </v-card>
                      </v-col>
                      <v-col cols="4">
                        <v-card
                          height="100%"
                          justify="center"
                        >
                          <v-card-title>放映回数</v-card-title>
                          <AnalysisPieChart
                            :pieData="pieData()"
                            :labels="regionListSortedCleaned()"
                            :height="140"
                            :styles="{'display':'flex', 'justify-content':'center'}"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-container fluid>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>CM一覧</v-card-title>
            <v-data-table
              :headers="headersAllData"
              :items="this.$store.getters.fullData"
              :items-per-page="15"
              class="elevation-1"
              :sort-by="['region']"
              multi-sort
              dense
            />
          </v-card>
        </v-col>

        <v-col cols="4">
          <SelectWidget />
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
import EvalIconDense from "@/components/common/modules/generals/EvalIconDense.vue"
import Toolbar from '@/components/common/modules/generals/Toolbar.vue'
import AnalysisBarChart from '@/components/common/modules/analysis/charts/AnalysisBarChart.vue'
import AnalysisPieChart from '@/components/common/modules/analysis/charts/AnalysisPieChart.vue'
import AnalysisHistogramChart from '@/components/common/modules/analysis/charts/AnalysisHistogramChart.vue'
import SelectWidget from "@/components/common/modules/widgets/SelectWidget.vue"

import { calceffect } from "@/components/mixins/calceffect";
import { evaluation } from "@/components/mixins/evaluation";

export default {
  name: "RegionAnalysis",
  components: {
    EvalIconDense,
    AnalysisBarChart,
    AnalysisPieChart,
    AnalysisHistogramChart,
    Toolbar,
    SelectWidget
  },
  data(){
    return{
      pageTitle: '地域',
      overlay: true,
      region: '',
      effectDict: {},
      regionListSorted: [],
      jsonData: [],
      csvTitle: "region",
      data: [10, 20, 30, 40, 50, 30],
      backgroundColor: ['rgba(75, 192, 192, 0.2)'],
      headers: [
        { text: '地域', align: 'start', value: 'region'},
        { text: '放映回数', value: 'count'},
        { text: 'セッション効率', value: 'effect'},
        { text: '評価', value: 'eval'},
      ],
      headersAllData: [
        { text: '地域', align: 'start', value: 'region' },
        { text: '日時', value: 'date' },
        { text: '局系列', value: 'station' },
        { text: '番組名', value: 'program' },
        { text: 'クリエイティブ', value: 'creative' },
        { text: '点数', value: 'effect' },
        { text: 'GRP', value: 'grp' },
        { text: '放映時刻', value: 'time' },
        // { text: '枠区分', value: 'slot' },
        // { text: 'カップリング', value: 'coupling' },
      ],
      startValue: -15,
    }
  },
  mixins: [calceffect, evaluation],
  methods: {
    barData() {
      const barData = []
      for (let region of this.regionListSorted) {
          barData.push(this.effectDict[region]['effect'])
      }
      return barData
    },
    pieData() {
      const pieData = []
      for (let region of this.regionListSorted) {
          pieData.push(this.effectDict[region]['count'])
      }
      return pieData
    },
    histogramData() {
      // startValue〜endValueまで1刻みのヒストグラムを作成する
      const startValue = -15
      const endValue = 30
      let histArray = new Array(endValue - startValue).fill(0)
      const effectList = this.$store.getters.fullData.map(obj => obj.effect)
      for (let effect of effectList) {
        let index = Math.round(effect) - startValue - 1
        // 外れ値は丸める
        if (index < 0) {
          index = 0
        } else if (index >= histArray.length) {
          index = histArray.length - 1
        }
        histArray[index] += 1
      }
      return histArray
    },
    regionListSortedCleaned() {
      const regionListSortedCleaned = []
      for (let region of this.regionListSorted) {
        regionListSortedCleaned.push(this.cleanRegionName(region))
      }
      return regionListSortedCleaned
    },
    tableData (){
      // v-data-tableに渡すデータ
      const tableData = []
      for (let region of this.regionListSorted) {
        tableData.push({
          region: this.cleanRegionName(region),
          effect: this.effectDict[region]['effect'],
          count: this.effectDict[region]['count'],
          eval: this.evaluation(this.effectDict[region]['effect'])
        })
      }
      return tableData
    },
    cleanRegionName(region) {
        // 県を削除する
        region = region.replace(/県|・/g, '')
        // 近畿は関西に表記変えする
        if (region == '近畿') {
        region = '関西'
        }
        return region
    },
    createRegionListSorted() {
        // セッション効率について降順で並べる
        this.regionListSorted = Object.keys(this.effectDict)
        this.regionListSorted.sort((a, b) => {
            return this.effectDict[b]['effect'] - this.effectDict[a]['effect']
        })
        this.region = this.regionListSorted[0]
    },
    //CSV出力用にデータを整形
    makeEffectJSON(){
      this.jsonData = []  // 初期化
      for (let key of Object.keys(this.effectDict)){
        this.jsonData.push({
          "地域": key,
          "セッション効率": this.effectDict[key]['effect'],
          "放映回数": this.effectDict[key]['count']
        })
      }
    },
    init() {
      var fullData = this.$store.getters.fullData
      var regionFullList = fullData.map(obj => obj.region)
      var effectFullList = fullData.map(obj => obj.effect)
      this.effectDict = this.calceffect(regionFullList, effectFullList)
      this.createRegionListSorted()
      this.makeEffectJSON()  // CSV用のデータ整形
      this.overlay = false
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>