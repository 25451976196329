<template>
  <v-app>
      <v-main class="mt-10">
        <v-container>
          <v-overlay
            :z-index="0"
            v-if="!targetList.length"
          >
            <v-progress-circular
                indeterminate
                color="#ffeaf4"
            ></v-progress-circular>
          </v-overlay>

          <v-card
            v-else
            max-width="700px"
            height="500px"
            class="mx-auto py-4"
          >
            <v-card-title>
              <v-icon>mdi-incognito</v-icon>
              &nbsp;対象選択
            </v-card-title>
            <v-divider/>


            <v-container style="max-width:620px; text-align: left;" class="mx-auto">

            <v-select
              v-model="selectedTarget"
              :items="targetList"
              label="ブランド"
              @input="(val) => viewCampaign(val)"
            />

            <div v-if="showCampaign">
            
              <v-select
                multiple
                :items="campaignSelectItems()"
                chips
                :menu-props="{ top: true, offsetY: true }"
                label="キャンペーン"
                v-model="selectedCampaignList"
                @input="(val) => checkIfSelectAll(val)"
              >
              <template v-slot:append-outer>
                <v-btn
                  v-if="ifSelectAll"
                  small
                  @click="removeAllCampaign()"
                >
                  全て外す
                </v-btn>
                <v-btn
                  v-else
                  small
                  @click="selectAllCampaign()"
                >
                  全選択
                </v-btn>
              </template>

              </v-select>

              <div v-if="checked()">
                <v-btn
                  :loading="loading"
                  raised
                  block
                  color="#FFCB00"
                  @click="toAnalysis()"
                >表示
                </v-btn>
              </div>

              <div v-else>
                <v-btn
                  disabled
                  block
                >選択してください
                </v-btn>
              </div>


            </div>
            </v-container>
          </v-card>
        </v-container>

    </v-main>
  </v-app>
</template>

<script>
import { campaignformat } from "@/components/mixins/campaignformat"
import axios from "axios"

export default {
  name: "Select",
  mixins: [campaignformat],
  data() {
    return {
      endpoint_get: 'https://vjc66ym1db.execute-api.ap-northeast-1.amazonaws.com/getFromUid/process',
      endpoint_post_campaign_list: 'https://683dj1snnf.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_post_userid: 'https://zptmg9kkbg.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_post: 'https://zd9s19kew9.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_post_budget: 'https://mewyuyltdd.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_get_region: 'https://wt3e3fzng1.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      userTable: [],
      targetList: [],
      selectedTarget: "", 
      campaignList: [],
      selectedCampaignList: [],
      showCampaign: false,
      ifSelectAll: false,
      loading: false,
    } 
  },
  methods: {
    campaignSelectItems() {
      const items = []
      for (let campaign of this.campaignList) {
        items.push({
          text: this.campaignformat(campaign),
          value: campaign
        })
      }
      return items
    },
    checked() {
      // チェックしているかどうか、表示ボタンを押せるようにする
      return this.selectedCampaignList.length
    },
    toAnalysis(){
      this.loading = true
      // TargetテーブルからCMデータを取得する
      axios.post(
        this.endpoint_post,
        JSON.stringify({
          target: this.selectedTarget,
          campaign_list: this.selectedCampaignList,
          if_select_all: this.ifSelectAll
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        this.commitAfterCMData(response)  // TargetテーブルからCMデータを取得した後にstoreへcommitする
        this.getBudgetData()  // Campaignテーブルから予算データを取得する
      }).catch(error => console.log(error))
    },
    commitAfterCMData(response) {
      // TargetテーブルからCMデータを取得した後にstoreへcommitする
      this.$store.commit('setTarget', this.selectedTarget)
      this.$store.commit('setSelectedCampaignList', this.selectedCampaignList)
      this.$store.commit('setCampaignList', this.campaignList)
      this.$store.commit('setFullData', response.data.body)
      this.$store.commit('setFullDataArchive', response.data.body)
    },
    getBudgetData() {
      // Campaignテーブルから予算データを取得する
      axios.post(
        this.endpoint_post_budget,
        JSON.stringify({
          target: this.$store.getters.target,
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        this.$store.commit('setBudgetList', response.data.body)
        this.getPopulation()  // Populationテーブルから全地域名を取得する
      }).catch(error => console.log(error))
    },
    getPopulation() {
      // Populationテーブルから全地域名を取得する
      axios.get(
        this.endpoint_get_region
      ).then(response => {
        this.$store.commit('setRegionList', response.data.body)
        this.$router.push({ name: "AnalysisDashboard" })
      }).catch(error => console.log(error))
    },
    viewCampaign(target){
      for(var i=0; i<this.userTable.length; i++){
        if (this.userTable[i].target==target) {
          var data = this.userTable[i]
          break
        }
      }
      this.selectedTarget = data.target
      data.campaign_list.sort()
      this.campaignList = data.campaign_list
      this.showCampaign = true

      // キャンペーンの選択を初期化する
      this.selectedCampaignList = []
      this.ifSelectAll = false
    },
    selectAllCampaign(){
      this.ifSelectAll = true
      this.selectedCampaignList = this.campaignList
    },
    removeAllCampaign(){
      this.ifSelectAll = false
      this.selectedCampaignList = []
    },
    checkIfSelectAll() {
      if (this.selectedCampaignList.length === this.campaignList.length) {
        this.ifSelectAll = true
      } else {
        this.ifSelectAll = false
      }
    },
    getAllDataFromUserTable(user_id) {
      // Userテーブルから全データを取得する
      axios.post(
        this.endpoint_post_campaign_list,
        JSON.stringify({
          user: user_id,
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        this.userTable = response.data.body
        this.targetList = response.data.body.map(obj => obj.target)
      }).catch(error => console.log(error))
    },
    init() {
      // データ選択を削除する
      this.$store.commit('setFullData', this.$store.getters.fullDataArchive)
      this.$store.commit('setIfExtracted', false)

      // 20240812追加 - 特定クライアントのテーブル取得
      axios.get(
        this.endpoint_get,
        {
          params: {
            username: this.$store.getters.username
          }
        }
      ).then(response => {
        console.log(response.data.body);
        this.userTable = response.data.body
        this.targetList = response.data.body.map(obj => obj.target)
        // this.$store.commit('setClientList', clientList)
        // this.$store.commit('setUserTable', userTable)
      }).catch(error => console.log(error))
      
      // 20240812コメントアウト - 各クライアントごとのアカウント作成のため下記コメントアウト
      // axios.post(
      //   this.endpoint_post_userid,
      //   JSON.stringify({
      //     username: this.$store.getters.username,
      //   }),
      //   {
      //     headers:{
      //     'Content-Type': 'application/json'
      //     }
      //   }
      // ).then(response => {
      //   this.getAllDataFromUserTable(response.data.body)
      // }).catch(error => console.log(error))
    }
  },
  computed: {
    fullDataArchive() {
      return this.$store.getters.fullDataArchive
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>
