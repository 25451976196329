<template>
  <v-container fluid>

    <v-card
        class="mx-auto pb-4"
        height="100%"
        elevation="6"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>

      <Toolbar :title="pageTitle" :jsonData="jsonData" :csvTitle="csvTitle" />

      <v-row>

        <v-col cols="5">
          <v-container>

              <table border="1" class="main-table">
                <thead>
                  <tr class="main-table__header">
                    <th class="main-table__header-corner"></th>
                    <th class="header-color">平日</th>
                    <th class="header-color">休日</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="hourRange in hourRangeList">
                    <tr :key="hourRange[0]">
                      <th>
                        {{ timeZone[hourRangeList.indexOf(hourRange)] }}
                        <br>
                        <span class="time-zone-figure">{{ timeZoneFigure[hourRangeList.indexOf(hourRange)] }}</span>
                      </th>

                      <template v-for="dayOfWeek in DaysHolyWeek">
                        <td 
                          :key="dayOfWeek"
                          v-if="effectSummaryDict[hourRange][dayOfWeek]"
                          :class="evaluation(effectSummaryDict[hourRange][dayOfWeek]['effect'])"
                        >
                          <v-row no-gutters>
                            <v-col
                              cols="9"
                              class="font-weight-bold"
                            >
                              {{ effectSummaryDict[hourRange][dayOfWeek]['effect'].toFixed(1) }}
                            </v-col>
                              <v-spacer/>
                              <v-divider vertical />
                            <v-col 
                              cols="3"
                            >
                              {{ effectSummaryDict[hourRange][dayOfWeek]['count'] }}
                            </v-col>
                          </v-row>
                        </td>

                        <td 
                          :key="dayOfWeek"
                          v-else
                          class="null"
                        >
                         - 
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
          </v-container>    
        </v-col>


        <v-col cols="7">
            <v-container>
              時間ごとの比較
              <table border="1" class="sub-table">
                <thead>
                  <tr>
                    <th></th>
                    <th
                      v-for="day in dayOfWeekForDisplay"
                      :key="day"
                      style="font-size: 1.5em;"
                    >
                      {{ day }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="key in Object.keys(effectDict)" :key="key">
                    <th>{{ key }}</th>
                    <td v-for="dayOfWeek in dayOfWeekForDisplay" :key="dayOfWeek">

                      <v-card
                        flat
                        v-if="effectDict[key][dayOfWeek]"
                        :class="evaluation(effectDict[key][dayOfWeek]['effect'])"
                        height="20"
                      >
                        <v-row no-gutters>
                          <v-col
                            cols="9"
                            class="font-weight-bold"
                          >
                            {{ effectDict[key][dayOfWeek]['effect'].toFixed(1) }}
                          </v-col>
                            <v-spacer/>
                            <v-divider vertical />
                          <v-col 
                            cols="3"
                          >
                            {{ effectDict[key][dayOfWeek]['count'] }}
                          </v-col>
                        </v-row>
                      </v-card>

                      <v-card
                        flat
                        class="null"
                        v-else
                      >
                        -
                      </v-card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-container>
        </v-col>

      </v-row>
    </v-card>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>CM一覧</v-card-title>
            <v-data-table
              :headers="headersAllData"
              :items="this.$store.getters.fullData"
              :items-per-page="15"
              class="elevation-1"
              :sort-by="['time']"
              multi-sort
              dense
            />
          </v-card>
        </v-col>

        <v-col cols="4">
          <SelectWidget />
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script> 
import Toolbar from '@/components/common/modules/generals/Toolbar.vue'
import SelectWidget from "@/components/common/modules/widgets/SelectWidget.vue"

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default {
  name: "TimeAnalysis",
  components: {
    Toolbar,
    SelectWidget
  },
  mixins: [calceffect, evaluation],
  data(){
    return{
      pageTitle: '時間帯',
      effectDict: {},
      effectSummaryDict: {},  // 平日と休日、時間帯で要約
      dayOfWeekStrJP: ['日','月','火','水','木','金','土'],
      dayOfWeekForDisplay: ['月','火','水','木','金','土','日'],
      DaysHolyWeek: ["平日", "休日"],
      timeZone: ["朝帯", "午前帯", "昼帯", "午後帯", "夕方帯", "プライム", "夜帯"],
      timeZoneFigure: ["5〜9時", "9〜12時", "12〜14時", "14〜16時", "16〜19時", "19〜23時", "23時〜"],
      hourRangeList: [[5, 8], [9, 11], [12, 13], [14, 15], [16, 18], [19, 22], [23, 28]],
      overlay: true,
      jsonData: [],
      csvTitle: "time",
      headersAllData: [
        { text: '放映時刻', align: 'start', value: 'time' },
        { text: '日時', value: 'date'},
        { text: '局系列', value: 'station' },
        { text: 'クリエイティブ', value: 'creative' },
        { text: '地域', value: 'region' },
        { text: '番組名', value: 'program' },
        { text: '点数', value: 'effect' },
        { text: 'GRP', value: 'grp' },
        // { text: '枠区分', value: 'slot' },
        // { text: 'カップリング', value: 'coupling' },
      ],
    }
  },
  methods: {
    //時間の表示形式を整形
    timeDataShaping(timeFullList) {
      // 時間帯データは'hh:mm:ss'の文字列なので5〜28の文字列に変換する
      const timeCleanedList = []
      for (let time of timeFullList) {
        timeCleanedList.push(this.cleanTime(time))
      }
      return timeCleanedList
    },
    cleanTime(time) {
      let new_time = time.substr(0, time.indexOf(':'))
      new_time = Number(new_time)
      if (new_time <= 4) {
        new_time += 24
      }
      return new_time
    },
    // 効果の表示形式を整形
    effectDataShaping(timeCleanedList, effectFullList, dateFullList) {
      // 2つの表のためにそれぞれdictに格納する
      for(let hour of this.hourList()){
        let count = 0
        const effectList = []
        const dayOfWeekList = []
        for (let timeCleaned of timeCleanedList) {
          if (hour === timeCleaned) {
            effectList.push(effectFullList[count])
            let dateSplited = dateFullList[count].split('-')
            // 配列の文字列を数値に変換する
            dateSplited = dateSplited.map( str => parseInt(str, 10) )
            dateSplited[1] -= 1  // Dateオブジェクトは1~12月を0~11で表しているため修正
            let actualDate = new Date(dateSplited[0], dateSplited[1], dateSplited[2])
            //24時〜28時は実際の日付の前日としてカウント
            if (hour >= 24) {
              actualDate.setDate(actualDate.getDate() - 1)
            }
            dayOfWeekList.push(this.dayOfWeekStrJP[actualDate.getDay()])
          }
          count += 1
        }
        this.effectDict[hour] = this.calceffect(dayOfWeekList, effectList)
      }
    },
    // 効果の表示形式を整形
    effectSummaryDataShaping(timeCleanedList, effectFullList, dateFullList) {
      // 2つの表のためにそれぞれdictに格納する
      for(let hourRange of this.hourRangeList){
        let count = 0
        const effectList = []
        const dayOfWeekList = []
        for (let timeCleaned of timeCleanedList) {
          if (timeCleaned >= hourRange[0] & timeCleaned <= hourRange[1]) {
            effectList.push(effectFullList[count])
            let dateSplited = dateFullList[count].split('-')
            // 配列の文字列を数値に変換する
            dateSplited = dateSplited.map( str => parseInt(str, 10) )
            dateSplited[1] -= 1  // Dateオブジェクトは1~12月を0~11で表しているため修正
            let actualDate = new Date(dateSplited[0], dateSplited[1], dateSplited[2])
            //24時〜28時は実際の日付の前日としてカウント
            if (timeCleaned >= 24) {
              actualDate.setDate(actualDate.getDate() - 1)
            }
            let dayOfWeekIndex = actualDate.getDay()
            if (dayOfWeekIndex == 0 | dayOfWeekIndex == 6) {
              dayOfWeekList.push("休日")
            } else {
              dayOfWeekList.push("平日")
            }
          }
          count += 1
        }
        this.effectSummaryDict[hourRange] = this.calceffect(dayOfWeekList, effectList)
      }
    },
    hourList() {
      // 5~28を要素として持つ配列を作る
      let hourList = []
      for (var i=0; i<24; i++) {
        hourList.push(i+5)
      }
      return hourList
    },
    //CSV出力用にデータを整形
    makeEffectJSON(){
      this.jsonData = []
      for (let key of Object.keys(this.effectDict)){
        let dictElement = {}
        dictElement['時間帯'] = key
        for (let dayOfWeek of this.dayOfWeekForDisplay) {
          if (Object.keys(this.effectDict[key]).includes(dayOfWeek)) {
            dictElement[dayOfWeek] = this.effectDict[key][dayOfWeek]['effect']
          } else {
            dictElement[dayOfWeek] = ''
          }
        }
        this.jsonData.push(dictElement)
      }
    },
    init() {
      const fullData = this.$store.getters.fullData
      const timeFullList = fullData.map(obj => obj.time)
      const timeCleanedList = this.timeDataShaping(timeFullList)
      const effectFullList = fullData.map(obj => obj.effect)
      const dateFullList = fullData.map(obj => obj.date)
      this.effectDataShaping(timeCleanedList, effectFullList, dateFullList)
      this.effectSummaryDataShaping(timeCleanedList, effectFullList, dateFullList)
      this.overlay = false
      this.makeEffectJSON()  // CSV用のデータ整形
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  border-bottom: #fff;
  width: 100%;
  table-layout: fixed;
  text-align: center;
}

/* サブテーブル */
.sub-table {
  font-size: .5em;
}
.sub-table tr {
  border-right:rgba(100, 100, 100, 0.2);
  border-bottom: rgba(100, 100, 100, 0.2);
}
.sub-table td {
  padding: 1px;
}
.sub-table thead td{
   border-right: #fff;
   border-bottom: #fff;
}
.sub-table thead tr{
   border-right: #fff;
}
.sub-table tbody td{
   height: 22px;
}

/* メインテーブル */
.main-table__header{
  font-size: 1rem;
}
.time-zone-figure {
  font-size: 0.75rem;
  font-weight: normal;
}
.main-table tbody tr{
  border-bottom: solid 2px white;
}
.main-table tbody tr:last-child{
  border-bottom: none;
}
.main-table tbody th{
  background-color: #999;
  color: white;
  padding: 5px 0;
}
.main-table td {
  padding: 2px;
}
.main-table{
  border-collapse: separate;
  border-spacing: 5px;
  width: 100%;
}
.main-table th, .main-table td{
  border-radius: 5px;
  text-align: center;
  padding: 10px 0;
}
.main-table .header-color{
  background-color: #eee;
}
.main-table__header-corner {
  border: none;
}

.bad{
  background-color: rgba(163, 158, 161, 0.7);
  color:#666;
}
.normal{
  background-color: rgba(255,200,230,0.8);
  color: #555;
}

.good{
  background-color: rgba(255,130,190,0.9);
  color: white;
}

.great{
  background-color: rgba(213,9,106,1);
  color: white;
}
</style>
