<template>
  <v-card
      elevation="6"
  >

    <v-card-title>
      <v-row align="start">
        <div class="text-subtitle-2 grey--text ml-6 mr-6 font-weight-bold">
          放映数
        </div>
        <div>
          <span
            class="text-h5 font-weight-black"
            v-text="cmCount()"
          ></span>
        </div>
      </v-row>
    </v-card-title>
  
    <HistogramChart
        :histogramData="histogramData()"
        :startValue="startValue"
        :height="160"
    />

  </v-card>
</template>

<script>
import HistogramChart from '@/components/common/modules/widgets/charts/HistogramChart.vue'
import { campaignformat } from "@/components/mixins/campaignformat"

export default {
  name: "DistributionWidget",
  components: {
    HistogramChart,
  },
  mixins: [campaignformat],
  data() {
    return {
      campaignList: [],
      effectList: [],
      regionList: [],
      startValue: -15,
    }
  },
  methods: {
    histogramData() {
      // startValue〜endValueまで1刻みのヒストグラムを作成する
      const startValue = -15
      const endValue = 30
      let histArray = new Array(endValue - startValue).fill(0)
      const effectList = this.$store.getters.fullData.map(obj => obj.effect)
      for (let effect of effectList) {
        let index = Math.round(effect) - startValue -1
        // 外れ値は丸める
        if (index < 0) {
          index = 0
        } else if (index >= histArray.length) {
          index = histArray.length - 1
        }
        histArray[index] += 1
      }
      return histArray
    },
    selectedTotalBudget() {
      let totalBudget = 0
      for (let selectedCampaign of this.$store.getters.selectedCampaignList) {
        totalBudget += this.budgetList[this.campaignList.indexOf(selectedCampaign)]
      }
      totalBudget = totalBudget.toLocaleString()
      return totalBudget
    },
    effectText() {
      const selectedCampaignList = this.$store.getters.selectedCampaignList
      if (selectedCampaignList.length == 1) {
        return this.effectList[this.campaignList.indexOf(selectedCampaignList[0])]
      } else {
        return ''
      }
    },
    lineData() {
      return this.effectList
    },
    campaignLabels() {
      const labels = []
      for (let campaign of this.campaignList) {
        labels.push(this.campaignformat(campaign))
      }
      return labels
    },
    cmCount() {
      return this.$store.getters.fullData.length
    },
    init() {
      for (let effectDict of this.$store.getters.budgetList) {
        // effectのデータが存在しない場合は飛ばす
        if (Object.keys(effectDict).includes('effect')) {
          this.effectList.push(effectDict['effect'])
          this.campaignList.push(effectDict['campaign'])
        }
      }
    }
  },
  computed: {
      fullData() {
          return this.$store.getters.fullData
      },
  },
  watch: {
      fullData() {
        this.overlay = true
        this.init()
      }
  },
  created(){
    this.init()
  },
}
</script>