<template>
    <h1 class="middle">
        404 Not Found
    </h1>
</template>

<script>
export default {
  name: "NotFound",
}
</script>

<style scoped>
.middle{
    text-align: center;
    margin: auto;
    top: 500px;
}
</style>