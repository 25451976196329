<template>
    <v-card
      class="mx-auto pb-4"

      color="#fff"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>

      <v-card-title class="text-h6">範囲を絞る</v-card-title>

      <!-- 地域 -->
      <div v-if="ifNotRegion()">
        <v-toolbar
          flat
          color="#ede4e1"
          dense
          :height="toolbarHeight"
        >
          <v-toolbar-title class="font-weight-bold subtitle-1">地域</v-toolbar-title>

          <v-spacer/>
          <v-btn
            small
            @click="regionRemoveAll()"
          >
            全解除
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row
            dense
            no-gutters
            align="center"
          >
            <v-col  
              cols="3"
              v-for="region in regionList"
              :key="region"
              class="my-n3"
            >
              <v-checkbox
                v-model="checkedRegionList"
                :label="cleanRegionName(region)"
                :value="region"
                dense
                style="transform:scale(0.85);"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- クリエイティブ -->
      <div v-if="ifNotCreative()">
        <v-toolbar
          flat
          color="#ede4e1"
          dense
          :height="toolbarHeight"
        >
          <v-toolbar-title class="font-weight-bold subtitle-1">クリエイティブ</v-toolbar-title>
          <v-spacer/>
          <v-btn
            small
            @click="creativeRemoveAll()"
          >
            全解除
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row
            dense
            no-gutters
          >
            <v-col
              v-for="creative in creativeList"
              :key="creative"
              cols="12"
              class="my-n3"
            >
              <v-checkbox
                v-model="checkedCreativeList"
                :label="creative"
                :value="creative"
                dense
                style="transform:scale(0.85);"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- 時間 -->
      <div v-if="ifNotTime()">
        <v-toolbar
          flat
          color="#ede4e1"
          dense
          :height="toolbarHeight"
        >
          <v-toolbar-title class="font-weight-bold subtitle-1">時間帯</v-toolbar-title>
          <v-spacer/>
          <v-btn
            small
            @click="timeRemoveAll()"
          >
            全解除
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row
            dense
            no-gutters
            align="center"
          >
            <v-col  
              cols="2"
              v-for="time in timeList"
              :key="time"
              class="my-n4"
            >
              <v-checkbox
                v-model="checkedTimeList"
                :label="time"
                :value="time"
                dense
                style="transform:scale(0.85);"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>


      <!-- 局 -->
      <div v-if="ifNotStation()">
        <v-toolbar
          flat
          color="#ede4e1"
          dense
          :height="toolbarHeight"
        >
          <v-toolbar-title class="font-weight-bold subtitle-1">局系列</v-toolbar-title>
          <v-spacer/>
          <v-btn
            small
            @click="stationRemoveAll()"
          >
            全解除
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row
            dense
            no-gutters
            align="center"
          >
            <v-col  
              cols="4"
              v-for="station in stationList"
              :key="station"
              class="my-n4"
            >
              <v-checkbox
                v-model="checkedStationList"
                :label="station"
                :value="station"
                dense
                style="transform:scale(0.85);"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div>
        <v-btn
          v-if="checked()"
          color="#FFCB00"
          @click="selectgraph()"
          width="80%"
        >表示
        </v-btn>
        <v-btn
          v-else
          disabled
          width="80%"
        >選択してください
        </v-btn>
      </div>
    </v-card>
</template>

<script>

export default {
  name: "SelectWidget",
  data(){
    return {
      regionList: [],
      creativeList: [],
      timeList: [],
      stationList: [],

      checkedRegionList: [],
      checkedCreativeList: [],
      checkedTimeList: [],
      checkedStationList: [],

      overlay: true,
      toolbarHeight: "35%"
    }
  },
  methods:{
    regionRemoveAll() {
      this.checkedRegionList = []
    },
    creativeRemoveAll() {
      this.checkedCreativeList = []
    },
    timeRemoveAll() {
      this.checkedTimeList = []
    },
    stationRemoveAll() {
      this.checkedStationList = []
    },
    cleanRegionName(region) {
        // 県を削除する
        region = region.replace(/県|・/g, '')
        if (region == '近畿') {
          return '関西'
        }
        return region
    },
    checked() {
      // チェックしているかどうか、表示ボタンを押せるようにする
      return this.checkedRegionList.length || this.checkedCreativeList.length || this.checkedTimeList.length || this.checkedStationList.length
    },
    ifNotRegion() {
      return !this.$route.path.match(/region/)
    },
    ifNotCreative() {
      return !this.$route.path.match(/creative/)
    },
    ifNotTime() {
      return !this.$route.path.match(/time/)
    },
    ifNotStation() {
      return !this.$route.path.match(/station/)
    },
    selectgraph(){
      // 選択されたグラフを表示する
      // 選択されたデータを格納する
      let regions = this.checkedRegionList
      let creatives = this.checkedCreativeList
      let times = this.checkedTimeList
      let stations = this.checkedStationList
      // 選択された項目を記憶する
      // 一つも選ばれていなければ全て選んだことと同義である
      // エリア
      if (this.checkedRegionList.length==0) {
        regions = this.regionList
      }
      // クリエイティブ
      if (this.checkedCreativeList.length==0) {
        creatives = this.creativeList
      }
      // 時間帯
      if (this.checkedTimeList.length==0) {
        times = this.timeList
      }
      // 放映局
      if (this.checkedStationList.length==0) {
        stations = this.stationList
      }

      this.$store.commit('setSelectedRegionList', regions)
      this.$store.commit('setSelectedCreativeList', creatives)
      this.$store.commit('setSelectedTimeList', times)
      this.$store.commit('setSelectedStationList', stations)
      
      const selectedThemeDict = {}
      if (regions!=this.regionList || !regions.length) {
        selectedThemeDict['エリア'] = regions
      }
      if (creatives!=this.creativeList || !creatives.length) {
        selectedThemeDict['素材'] = creatives
      }
      if (times!=this.timeList || !times.length) {
        selectedThemeDict['時間帯'] = times
      }
      if (stations!=this.stationList || !stations.length) {
        selectedThemeDict['局系列'] = stations
      }
      this.$store.commit('setSelectedThemeDict', selectedThemeDict)

      // 選択されたデータを抽出する
      var selectedData = this.extractSelectedData(regions, creatives, times, stations)
      this.$store.commit('setIfExtracted', true)
      this.$store.commit('setFullData', selectedData)

      this.regionRemoveAll()
      this.creativeRemoveAll()
      this.timeRemoveAll()
      this.stationRemoveAll()

      this.scrollTop()
    },
    extractSelectedData(regions, creatives, times, stations) {
      // 選択されたデータを抽出する
      var selectedData = []
      for(var data of this.$store.getters.fullDataArchive){
        var region_flag = regions.includes(data.region)
        var creative_flag = creatives.includes(data.creative)

        // data.timeは'hh:mm:ss'の文字列なので5〜28の文字列に変換する
        var time_cleaned = this.cleanTime(data.time)
        var time_flag = times.includes(time_cleaned)

        var station_flag = stations.includes(data.station)
        if(region_flag && creative_flag && time_flag && station_flag){
          selectedData.push(data)
        }
      }
      return selectedData
    },
    cleanTime(time) {
      let new_time = time.substr(0, time.indexOf(':'))
      new_time = Number(new_time)
      if (new_time <= 4) {
        new_time += 24
      }
      return String(new_time)
    },
    createStationList(archive) {
      // 時間帯
      const timeFullList = archive.map(obj => obj.time)
      // 時間帯データは'hh:mm:ss'の文字列なので5〜28の文字列に変換する
      for (let time of Array.from(new Set(timeFullList))) {
        this.timeList.push(this.cleanTime(time))
      }
      this.timeList = Array.from(new Set(this.timeList))  // ユニークな値を抽出する
      // 昇順にソートする
      this.timeList.sort((a, b) => {
        return a - b
      })
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    init() {
      const archive = this.$store.getters.fullDataArchive
      // 地域
      const regionFullList = archive.map(obj => obj.region)
      this.regionList = Array.from(new Set(regionFullList))
      // クリエイティブ
      const creativeFullList = archive.map(obj => obj.creative)
      this.creativeList = Array.from(new Set(creativeFullList))
      // 時間帯
      this.createStationList(archive)
      // 局系列
      const stationFullList = archive.map(obj => obj.station)
      this.stationList = Array.from(new Set(stationFullList))

      this.overlay = false
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>

<style scoped>

</style>