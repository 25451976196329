<template>
    <v-toolbar
        flat
    >
        <v-toolbar-title>{{ title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-row
          v-if="ifTime()"  
          dense
          no-gutters
        >
          <v-col>
            <v-row
              dense
              no-gutters
            >
              <v-col cols="12">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div 
                      v-bind="attrs"
                      v-on="on"
                      class="caption grey--text font-weight-bold"
                    >
                      セッション効率
                    </div>
                  </template>
                  <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
                </v-tooltip>
              </v-col>
              <v-col cols="1" class="mt-n2">
                <span class="text-caption">低</span>
              </v-col>
              <v-col cols="10">
                <v-card style="background:linear-gradient(to right, #ffd5ea, #d5006a); vertical-align: middle;" height="50%">
                  &nbsp;
                </v-card>
              </v-col>
              <v-col cols="1" class="mt-n2">
                <span class="text-caption">高</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <vue-json-to-csv
            v-if="jsonData&&ifAdmin()"
            :json-data="jsonData"
            :csv-title="csvTitleFixed()"
        >
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon 
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                </template>
                <span>csv形式でダウンロード</span>
            </v-tooltip>
        </vue-json-to-csv>
    </v-toolbar>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "Toolbar",
    components: {
        VueJsonToCsv,
    },
    props: ['jsonData', 'csvTitle', 'title'],
    computed: {
    },
    methods: {
        ifAdmin() {
            return this.$store.getters.admin
        },
        ifTime() {
            return this.$route.path.match(/time/)
        },
        nowTime() {
            let date = new Date();  //new演算子でオブジェクトのインスタンスを生成
            // それぞれ文字列で取得。2桁に0埋め
            const year = String(date.getFullYear())
            const month = ('00' + (date.getMonth()+1)).slice( -2 )
            const day = ('00' + date.getDate()).slice( -2 )
            const hour = ('00' + date.getHours()).slice( -2 )
            const minute = ('00' + date.getMinutes()).slice( -2 )
            const second = ('00' + date.getSeconds()).slice( -2 )
            const now = year + month + day + hour + minute + second
            return now
        },
        csvTitleFixed() {
            let text = ''
            for (let campaign of this.$store.getters.selectedCampaignList) {
                text = text + '_' + campaign
            }
            return this.csvTitle + text
        },
    },
}
</script>