<script>
import { Doughnut} from 'vue-chartjs'
import chartjsPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
  extends: Doughnut,
  name: 'AnalysisPieChart',
  props: [
    'labels', 'pieData', 'selectedLabel'
  ],
  data () {
    return {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(146, 225, 193, 0.5)',
            borderColor: 'rgba(100, 100, 100, 0.2)',
            borderWidth: 0.5,
            datalabels: {
              formatter: (value, context) => {
                const label = context.chart.data.labels[context.dataIndex]
                return `${label}`
              }
            }
          },
        ]
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
          padding: 10
        },
      }
    }
  },
  methods: {
    showGraph() {
      this.addPlugin(chartjsPluginDatalabels)
      this.data.datasets[0].data = this.pieData
      this.data.labels = this.labels
      this.renderChart(this.data, this.options)
    },
  },
  mounted () {
    this.showGraph()
  },
  // 選択されたラベルが変更されたらグラフを再描画する
  watch: {
    pieData: function() {
      this.showGraph()
    }
  }
}
</script>