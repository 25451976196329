<template>
    <v-card
        elevation="6"
    >

        <v-overlay
          v-if="overlay"
          absolute
          opacity="0.1"
        >
          <v-progress-circular
              indeterminate
              color="#ec6d88"
          ></v-progress-circular>
        </v-overlay>

        <v-toolbar flat dense>
            <v-avatar color="#CCCF9A" size="40" class="mt-1 ml-n1">
                <v-icon color="#6D6F3E">
                    mdi-earth
                </v-icon>
            </v-avatar>
            <v-toolbar-title class="text-subtitle-2 font-weight-bold mt-4 ml-2" style="color: gray;">エリア</v-toolbar-title>
            <v-spacer/>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        icon 
                        to="region"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mt-3">mdi-arrow-top-right-bold-box-outline</v-icon>
                    </v-btn>
                </template>
                <span>詳細を表示</span>
            </v-tooltip>
        </v-toolbar>

        <v-container fluid class="mt-n6">
            <v-row
                justify="center"
                align="center"
            >
                <v-col cols="7">
                    <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                        >
                            <span class="text-h5 font-weight-bold" style="color: black;">{{ cleanDisplayRegionName(region) }}</span>
                            &nbsp;
                            <span style="font-size: .8em;">/</span>
                            &nbsp;
                            <span class="font-weight-bold" style="font-size: .9em;">{{ Object.keys(effectDict).length }}</span>
                            <span style="font-size: .7em;">地</span>
                            <span style="font-size: .7em;">域</span>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-for="region in regionListSorted"
                            :key="region"
                            @click="changeRegion(region)"
                        >
                            <v-list-item-title>{{ cleanRegionName(region) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>

                </v-col>
                <v-col cols="5">
                    <BarChart
                        :barData="barData()"
                        :labels="regionListSorted"
                        :height="100"
                        :selectedLabel="region"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container>
        <v-row>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        点数
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        {{ effectDict[region]['effect'] }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        放映回数
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        {{ effectDict[region]['count'] }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        評価
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        <EvalIconDense :eval="evaluation(effectDict[region]['effect'])" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        </v-container>


    </v-card>

</template>

<script>
import EvalIconDense from '@/components/common/modules/generals/EvalIconDense.vue'
import BarChart from '@/components/common/modules/widgets/charts/BarChart.vue'

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default({
    name: "RegionWidget",
    components: {
        BarChart,
        EvalIconDense,
    },
    data(){
        return{
            overlay: true,
            region: '関東',
            effectDict: {},
            regionListSorted: [],
        }
    },
    mixins: [calceffect, evaluation],
    methods: {
        changeRegion(region) {
            this.region = region
        },
        cleanRegionName(region) {
            // 県を削除する
            region = region.replace(/県/g, '')
            // 近畿は関西に表記変えする
            if (region == '近畿') {
            region = '関西'
            }
            return region
        },
        cleanDisplayRegionName(region) {
            // 表示用の地域名を変更する
            // 近畿は関西に表記変えする
            if (region == '近畿') {
            region = '関西'
            } else if (region == '香川県・岡山県') {
                region = '香川岡山'
            }
            return region
        },
        barData() {
            const barData = []
            for (let region of this.regionListSorted) {
                barData.push(this.effectDict[region]['effect'])
            }
            return barData
        },
        backgroundColor() {
            const colorList = []
            for (let region of this.regionListSorted) {
                if (region == this.region) {
                    colorList.push('rgba(75, 192, 192, 0.2)')
                } else {
                    colorList.push('rgba(75, 192, 192, 0.2)')
                }                
            }
            return colorList
        },
        ifBest() {
            return this.regionListSorted.indexOf(this.region) == 0
        },
        createRegionListSorted() {
            // セッション効率について降順で並べる
            this.regionListSorted = Object.keys(this.effectDict)
            this.regionListSorted.sort((a, b) => {
                return this.effectDict[b]['effect'] - this.effectDict[a]['effect']
            })
            this.region = this.regionListSorted[0]
        },
        init() {
            var fullData = this.$store.getters.fullData
            var regionFullList = fullData.map(obj => obj.region)
            var effectFullList = fullData.map(obj => obj.effect)
            this.effectDict = this.calceffect(regionFullList, effectFullList)
            this.createRegionListSorted()
            this.overlay = false
        }
    },
    computed: {
        fullData() {
        return this.$store.getters.fullData
        },
    },
    watch: {
        fullData() {
            this.overlay = true
            this.init()
        }
    },
    created(){
        this.init()
    },
})
</script>

<style scoped>
::v-deep .v-select__selections input { width: 0em }

.widget-title {
    color: #a7894b;
}

.feature-title {
    font-size: .5em;
    color: gray;
    margin: -2em 0;
    padding: 0;
}

.feature-content {
    font-size: 1em;
    margin-bottom: 1em;
    padding: 0;
}

::v-deep .v-btn {
  display: block !important;
  white-space: normal !important;
  overflow-wrap: anywhere !important;
}
</style>