<script>
// ヒストグラムを描画する
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'HistogramChart',
  props: [
    'histogramData', 'startValue',
  ],
  data () {
    return {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(254, 86, 74, 0.6)',
            border: false,
          },
        ]
      },
      options: {
        responive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
          padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 20,
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'セッション効率',
              fontSize: 11,
              padding: -10,
            },
            gridLines: {
              display: false,
            },
            categoryPercentage: 1,
            ticks: {
              fontSize: 9,
            },
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              suggestedMin: 0,
              fontSize: 10,
            }
          }]
        },
      }
    }
  },
  methods: {
    showGraph() {
      this.data.labels = this.createLabels()
      this.data.datasets[0].data = this.histogramData
      this.renderChart(this.data, this.options)
    },
    createLabels() {
      const labels = []
      for (let i=0; i<this.histogramData.length; i++) {
        labels.push(this.startValue+i+1)
      }
      return labels
    },
  },
  mounted () {
    this.showGraph()
  },
  // 選択されたラベルが変更されたらグラフを再描画する
  watch: {
    histogramData: function() {
      this.showGraph()
    }
  }
}
</script>