<template>
  <v-container fluid>
    <v-card>
      <Toolbar :title="pageTitle" :jsonData="jsonData" :csvTitle="csvTitle" />

      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="検索する"
        ></v-text-field>
      </v-card-title>
      
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="cmListItems()"
            :items-per-page="50"
            class="elevation-1"
            :search="search"
            multi-sort
            dense
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Toolbar from '@/components/common/modules/generals/Toolbar.vue'

export default {
  name: "CMList",
  components: {
    Toolbar,
  },
  data() {
    return {
      search: '',
      pageTitle: 'テレビCM一覧',
      jsonData: [],
      csvTitle: "list",
      headers: [
        { text: '日時', align: 'start', value: 'date' },
        { text: '放映時刻', value: 'time' },
        { text: '局系列', value: 'station' },
        { text: '地域', value: 'region' },
        { text: '番組名', value: 'program' },
        { text: 'ジャンル', value: 'genre' },
        { text: '点数', value: 'effect' },
        { text: 'GRP', value: 'grp' },
        { text: 'クリエイティブ', value: 'creative' },
        { text: '枠区分', value: 'slot' },
      ],
    } 
  },
  methods: {
    cmListItems() {
      let cmItems = JSON.parse(JSON.stringify(this.$store.getters.fullData))
      // 数値データを小数点1桁で0埋めする
      for (let item of cmItems) {
        item['effect'] = Number(item['effect']).toFixed(1)
        item['grp'] = Number(item['grp']).toFixed(1)
      }
      return cmItems
    },
    //CSV出力用にデータを整形
    makeEffectJSON() {
      this.jsonData = []  // 初期化
      for (let item of this.$store.getters.fullData){
        let keys = Object.keys(item)
        let slot = ''
        let genre = ''
        if (keys.includes('slot')) {
          slot = item['slot']
        }
        if (keys.includes('genre')) {
          genre = item['genre']
        }
        this.jsonData.push({
            "日時": item['date'],
            "局系列": item['station'],
            "地域": item['region'],
            "番組名": item['program'],
            "クリエイティブ": item['creative'],
            "点数": item['effect'],
            "GRP": item['grp'],
            "放映時刻": item['time'],
            "秒数": item['length'],
            "ジャンル": genre,
            "枠区分": slot,
        })
      }
    },
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.makeEffectJSON()
    }
  },
  created(){
    this.makeEffectJSON()
  },
}
</script>