<template>
  <v-main>
      <div class="auth-screen">
        <v-overlay
          z-index="0"
        />
        <amplify-authenticator>
        </amplify-authenticator>
      </div>
  </v-main>
</template>

<script>
// アカウント管理はrouter.jsで行っている

export default {
  name: "SignIn",
  data () {
    return {
    }
  },
}

</script>

<style scoped>
.auth-screen {
  position: relative;
  width: 100%;
  background-image: url('~@/assets/BackgroundSignin.jpeg');
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
}
</style>