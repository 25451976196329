export const calceffect = {
    methods: {
        // 各分析におけるセッション効率をまとめる
        calceffect(subjectFullList, effectFullList){
            // subjectは地域やクリエイティブの分類データ
            var subjectList = Array.from(new Set(subjectFullList));
            var newEffectList = effectFullList.concat()  // shallow copy
            var sampleSize = newEffectList.length

            // 昇順にソートする
            newEffectList.sort((a, b) => {
                return a - b
            })
            var returnDict = {}  // 返り値の連想配列

            for(var subjectTitle of subjectList){
                var effect = 0
                var count = 0
                var subjectCount = 0  // 該当のタイトルを持つデータ数
                for(var subject of subjectFullList){
                    // 該当のタイトルを持つデータの効果を足し合わせて後で平均を取る
                    if(subjectTitle === subject){
                        // 100件以上データがあれば外れ値を丸める
                        if (sampleSize < 100) {
                            effect += effectFullList[count]
                            subjectCount += 1
                        } else {
                            if (effectFullList[count] > newEffectList[Math.round(sampleSize*0.975)]) {
                                // 上側2.5％点を超えるもの
                                effect += newEffectList[Math.round(sampleSize*97.5/100)]
                            } else if (effectFullList[count] <= newEffectList[Math.round(sampleSize*0.025)]) {
                                // 下側2.5％点を超えるもの
                                effect += newEffectList[Math.round(sampleSize*2.5/100)]
                            } else {
                                effect += effectFullList[count]
                            }
                            subjectCount += 1
                        }
                    }
                    count += 1
                }
                effect = Math.round(effect/subjectCount * 100)/100  // 小数点第2位までで平均を取る
                returnDict[subjectTitle] = {'effect': effect, 'count': subjectCount}
            }
            
            return returnDict
        }
    }
}