<template>
  <v-card
      elevation="6"
  >

    <v-overlay
      v-if="overlay"
      absolute
      opacity="0.1"
    >
      <v-progress-circular
          indeterminate
          color="#ec6d88"
      ></v-progress-circular>
    </v-overlay>

    <v-card-title>
      <v-row align="start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div 
                v-bind="attrs"
                v-on="on"
                class="text-subtitle-2 grey--text ml-6 mr-6 font-weight-bold"
              >
                セッション効率
              </div>
            </template>
            <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
          </v-tooltip>
        <div>
          <span
            class="text-h5 font-weight-black"
            v-text="effectText()"
          ></span>
        </div>
      </v-row>
    </v-card-title>
  
    <LineChart
      :lineData="lineData()"
      :labels="campaignLabels()"
      :selectedLabelList="selectedCampaignLabelList()"
      :height="120"
    />

  </v-card>
</template>

<script>
import LineChart from '@/components/common/modules/widgets/charts/LineChart.vue'
import { campaignformat } from "@/components/mixins/campaignformat"

export default {
  name: "CampaignEffectWidget",
  components: {
    LineChart,
  },
  mixins: [campaignformat],
  data() {
    return {
      campaignList: [],
      effectList: [],
      regionList: [],
      overlay: true,
    }
  },
  methods: {
    selectedTotalBudget() {
      let totalBudget = 0
      for (let selectedCampaign of this.$store.getters.selectedCampaignList) {
        totalBudget += this.budgetList[this.campaignList.indexOf(selectedCampaign)]
      }
      totalBudget = totalBudget.toLocaleString()
      return totalBudget
    },
    effectText() {
      const selectedCampaignList = this.$store.getters.selectedCampaignList
      if (selectedCampaignList.length == 1) {
        return this.effectList[this.campaignList.indexOf(selectedCampaignList[0])]
      } else {
        return ''
      }
    },
    lineData() {
      return this.effectList
    },
    campaignLabels() {
      const labels = []
      for (let campaign of this.campaignList) {
        labels.push(this.campaignformat(campaign))
      }
      return labels
    },
    selectedCampaignLabelList() {
      const labels = []
      for (let campaign of this.$store.getters.selectedCampaignList) {
        labels.push(this.campaignformat(campaign))
      }
      return labels
    },
    init() {
      // 初期化
      this.effectList = []
      this.campaignList = []
      for (let effectDict of this.$store.getters.budgetList) {
        // effectのデータが存在しない場合は飛ばす
        if (Object.keys(effectDict).includes('effect')) {
          this.effectList.push(effectDict['effect'])
          this.campaignList.push(effectDict['campaign'])
        }
      }
      this.overlay = false
    }
  },
  computed: {
    selectedCampaignList() {
      return this.$store.getters.selectedCampaignList
    },
  },
  watch: {
    selectedCampaignList() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>