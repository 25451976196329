<template>
    <v-card
        class="mx-auto pb-4"
        elevation="6"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>

      <v-toolbar
        flat
        dense
        class="mb-n4"
      >
        <v-toolbar-title to="program">番組</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-col cols="auto">
        <v-select
            :items="stationList"
            v-model="station"
            class="mt-4"
        />
        </v-col>

        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn 
                icon 
                to="program"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
            </v-btn>
            </template>
            <span>詳細を表示</span>
        </v-tooltip>
      </v-toolbar>

      <v-container>
      <v-tabs
        v-model="selectedEvalIndex"
        background-color="transparent"
        grow
        height="35"
        class="mb-2"
      >
        <v-tab
          v-for="item in evalList"
          :key="item"
        >
          <EvalIconDense :eval="item" />
        </v-tab>
      </v-tabs>

        <v-data-table
          :headers="headers"
          :items="this.tableData()"
          :items-per-page="10"
          class="elevation-1"
          dense
        />
      </v-container>

    </v-card>
</template>

<script> 
import EvalIconDense from '@/components/common/modules/generals/EvalIconDense.vue'

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default {
  name: "ProgramWidget",
  mixins: [calceffect, evaluation],
  components: { 
    EvalIconDense,
  },
  data(){
    return{
      effectDictByStationEval: {},
      station: "",
      stationList: [],
      headers: [
        { text: '番組名', align: 'start', value: 'program'},
        { text: '放映回数', value: 'count'},
      ],
      overlay: true,
      selectedEvalIndex: 0,
      evalList: ['great', 'good', 'normal', 'bad'],
      stationOrderList: ['日テレ', '日本テレビ', 'テレビ朝日', 'TBS', 'フジテレビ', 'テレビ東京'],  // テレビ局はこの順番に表示する
    }
  },
  methods:{
    stationListSorted() {
      const listSorted = []
      for (let station of this.stationOrderList) {
        if (this.stationList.includes(station)) {
          listSorted.push(station)
        }
      }
      return listSorted
    },
    tableData (){
      // v-data-tableに渡すデータ
      const evalValue = this.evalList[this.selectedEvalIndex]
      return this.effectDictByStationEval[this.station][evalValue]
    },
    effectDataShaping(stationFullList, programFullList, effectFullList) {
      // 局ごとにデータを格納する
      for(let station of this.stationList){
        let count = 0
        const effectList = []
        const programList = []
        for (let stationName of stationFullList) {
          if (station === stationName) {
            effectList.push(effectFullList[count])
            programList.push(programFullList[count])
          }
          count += 1
        }
        const effectDict = this.calceffect(programList, effectList)
        this.storeEffectDictForEachEval(station, effectDict)
      }
    },
    storeEffectDictForEachEval(station, effectDict) {
      // 4段階評価を加え、局系列別かつ評価別にデータを格納する
      // 評価ごとにデータを格納する連想配列を初期化する
      const evalListArray = {}
      for (let evalName of this.evalList) {
        evalListArray[evalName] = []
      }
      for (let key of Object.keys(effectDict)) {
        let evalValue = this.evaluation(effectDict[key]['effect'])
        effectDict[key]['eval'] = evalValue
        const dictElement = {'program': key, 'count': effectDict[key]['count'], 'effect': effectDict[key]['effect']}
        evalListArray[evalValue].push(dictElement)
      }
      for (let key of Object.keys(evalListArray)) {
        // countについて昇順にソートする
        evalListArray[key].sort((first, second) => {
          return second['count'] - first['count']
        })
      }
      // 局系列別にデータを格納する
      this.effectDictByStationEval[station] = evalListArray
    },
    init() {
      const fullData = this.$store.getters.fullData
      const stationFullList = fullData.map(obj => obj.station)
      const effectFullList = fullData.map(obj => obj.effect)
      const programFullList = fullData.map(obj => obj.program)
      // 局系列のユニークな値を抽出する
      this.stationList = Array.from(new Set(stationFullList))
      this.effectDataShaping(stationFullList, programFullList, effectFullList)
      this.overlay = false
      this.stationList = this.stationListSorted(this.stationList)
      this.station = this.stationList[0]
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-top: 0px none;
  border-left: 0px none;
  border-right: 0px none;
  table-layout: fixed;
  color: #470000;
  text-align: center;
  font-size: .8em;
}

table th,
table td {
  padding: .5em;
}

::v-deep .v-select__selections input { width: 0.5em }

</style>