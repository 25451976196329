import Vue from "vue"
import VueRouter from "vue-router"

import store from "./store.js"
import Auth from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"

// common
import Index from '@/components/common/index/Index.vue'
import AnalysisIndex from '@/components/common/index/AnalysisIndex.vue'
import SignIn from '@/components/common/pages/SignIn.vue'
import AnalysisDashboard from '@/components/common/pages/AnalysisDashboard.vue'
import CMList from '@/components/common/modules/analysis/CMList.vue'
import NotFound from '@/components/common/pages/NotFound.vue'

// general
import Select from '@/components/general/Select.vue'

// admin
import AdminSelect from '@/components/admin/AdminSelect.vue'
import RegisterSelect from '@/components/admin/RegisterSelect.vue'

// admin/register
import UploadCreative from '@/components/admin/register/UploadCreative.vue'
import AddTarget from '@/components/admin/register/AddTarget.vue'
import AddAccount from '@/components/admin/register/AddAccount.vue'
import AddCampaign from '@/components/admin/register/AddCampaign.vue'
import TvIdGenerate from '@/components/admin/register/TvIdGenerate.vue'
import Budget from '@/components/admin/register/Budget.vue'
import EndCampaign from '@/components/admin/register/EndCampaign.vue'

// common/analysis
import RegionAnalysis from '@/components/common/modules/analysis/RegionAnalysis.vue'
import ProgramAnalysis from '@/components/common/modules/analysis/ProgramAnalysis.vue'
import TimeAnalysis from '@/components/common/modules/analysis/TimeAnalysis.vue'
import CreativeAnalysis from '@/components/common/modules/analysis/CreativeAnalysis.vue'
import StationAnalysis from '@/components/common/modules/analysis/StationAnalysis.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    //アクセスにサインインが必要なページには meta: { requiresAuth: true } を指定

    { path: '/', redirect: { name: 'AdminSelect' }, meta: { requireAuth: true }},

    // common
    { path: '/signin', name:'SignIn', component: SignIn },

    // general
    { path: '/', 
      component: Index,
      children: [
        { path: 'select', name:'Select', component: Select, meta: { requiresAuth: true } },
        { path: 'analysis',
          component: AnalysisIndex,
          children: [
            { path: 'dashboard', name: 'AnalysisDashboard', component: AnalysisDashboard, meta: { requiresAuth: true }},
            { path: 'region', name:'RegionAnalysis', component: RegionAnalysis, meta: { requiresAuth: true } },
            { path: 'time', name:'TimeAnalysis', component: TimeAnalysis, meta: { requiresAuth: true } },
            { path: 'creative', name:'CreativeAnalysis', component: CreativeAnalysis, meta: { requiresAuth: true } },
            { path: 'program', name:'ProgramAnalysis', component: ProgramAnalysis, meta: { requiresAuth: true } },
            { path: 'station', name:'StationAnalysis', component: StationAnalysis, meta: { requiresAuth: true } },
            { path: 'list', name:'CMList', component: CMList, meta: { requiresAuth: true } },
          ]},
      ] },

    // admin
    {
      path: '/admin',
      component: Index,
      children: [
        { path: 'select', name:'AdminSelect', component: AdminSelect, meta: { requiresAdmin: true } },
        { path: 'analysis', 
          component: AnalysisIndex,
          children: [
            { path: 'dashboard', name: 'AdminAnalysisDashboard', component: AnalysisDashboard, meta: { requiresAdmin: true }},
            { path: 'region', name:'AdminRegionAnalysis', component: RegionAnalysis, meta: { requiresAdmin: true } },
            { path: 'time', name:'AdminTimeAnalysis', component: TimeAnalysis, meta: { requiresAdmin: true } },
            { path: 'creative', name:'AdminCreativeAnalysis', component: CreativeAnalysis, meta: { requiresAdmin: true } },
            { path: 'program', name:'AdminProgramAnalysis', component: ProgramAnalysis, meta: { requiresAdmin: true } },
            { path: 'station', name:'AdminStationAnalysis', component: StationAnalysis, meta: { requiresAdmin: true } },
            { path: 'list', name:'AdminCMList', component: CMList, meta: { requiresAdmin: true } },
          ]},
        // register
        { path: 'register/select', name:'RegisterSelect', component: RegisterSelect, meta: { requiresAdmin: true } },
        { path: 'register/creative', name: 'UploadCreative', component: UploadCreative, meta: { requiresAdmin: true } },
        { path: 'register/tv-id-generate', name:'TvIdGenerate', component: TvIdGenerate, meta: { requiresAdmin: true } },
        { path: 'register/target', name:'AddTarget', component: AddTarget, meta: { requiresAdmin: true } },
        { path: 'register/account', name:'AddAccount', component: AddAccount, meta: { requiresAdmin: true } },
        { path: 'register/campaign', name:'AddCampaign', component: AddCampaign, meta: { requiresAdmin: true } },
        { path: 'register/budget', name:'Budget', component: Budget, meta: { requiresAdmin: true } },
        { path: 'register/end-campaign', name:'EndCampaign', component: EndCampaign, meta: { requiresAdmin: true } },
      ]
    },

    //上記以外のURLの場合エラーページを表示
    { path: '*', name:'NotFound', component: NotFound}
  ],
  //F5キーなどでページ更新時に画面トップへ自動スクロール
  scrollBehavior(){
    return {x:0,y:0}
  }
});
  

var user

function getUser() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data)
      return data
    }
  }).catch(() => {
      store.commit('setUser', null)
      return null
  })
}

function routerPush(username) {
  //ここで管理者のusernameを参照し判定
  if (username === "admin") {
    store.commit('setAdmin', true)
    router.push({ name: "AdminSelect" }).catch(() => {})
  } else {
    store.commit('setAdmin', false)
    router.push({ name: "Select" }).catch(() => {})
  }
}

// ログイン状態管理
Hub.listen("auth", async (data) => {
  if (data.payload.event === 'signOut'){
      user = null
      store.commit('setUser', user)
      router.push({path: '/signin'})
  } else if (data.payload.event === 'signIn') {
      user = await getUser()
      store.commit('setUser', user)
      routerPush(user.username)
  }
})

// requireAuth: true のページでユーザー未認証の場合はサインインページに遷移させる
// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser()
    if (!user) {
        return next({
            path: '/signin'
        })
    }
    store.commit('setUsername', user.username)
    return next()
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    user = await getUser()
    if (!user) {
        return next({
            path: '/signin'
        })
    } else if (user.username != "admin") {
      store.commit('setAdmin', false)
      return next({
        name: 'Select'
    })
    }
    store.commit('setAdmin', true)
    return next()
  }
  return next()
})

export default router;