<template>
  <v-app>
      <v-main class="mt-10">
        <v-container>
          <v-overlay
            :z-index="0"
            v-if="!clientList.length"
          >
            <v-progress-circular
                indeterminate
                color="#ffeaf4"
            ></v-progress-circular>
          </v-overlay>

          <v-card
            v-else
            max-width="700px"
            height="500px"
            class="mx-auto py-4"
          >

            <v-card-title>予算登録</v-card-title>
            <v-divider/>


            <v-container style="max-width:620px; text-align: left;" class="mx-auto">

            <v-select
              v-model="client"
              :items="clientList"
              label="クライアント"
              @input="(val) => viewTarget(val)"
            />
        
            <div v-if="showTarget">
              <v-select
                v-model="selectedTarget"
                :items="targetList"
                label="ブランド"
                @input="(val) => viewCampaign(val)"
              />
            </div>

            <div v-if="showCampaign">
            
              <v-select
                :items="campaignSelectItems()"
                label="キャンペーン"
                v-model="selectedCampaign"
              />
            </div>

            <div v-if="checked()">
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="region"
                    :items="regionList"
                    label="地域"
                  />
                </v-col>

                <v-col cols="8">
                  <v-text-field
                    v-model="budget"
                    label="予算"
                    type="number"
                  />
                </v-col>
              </v-row>

              <div v-if="ongoing">
                <v-btn
                  v-if="budget && region"
                  :loading="loading"
                  raised
                  block
                  color="#FFCB00"
                  @click="register()"
                >登録
                </v-btn>
                <v-btn
                  v-else
                  disabled
                  block
                >入力してください
                </v-btn>
              </div>

              <div v-else>
                <v-btn
                  color="#FFAAAA"
                  block
                  @click="reset()">
                    リセット
                </v-btn>
                <p>登録しました</p>
              </div>
            </div>

            </v-container>
          </v-card>
        </v-container>

    </v-main>
  </v-app>
</template>

<script>
import { campaignformat } from "@/components/mixins/campaignformat"
import axios from "axios"

export default {
  name: "Budget",
  mixins: [campaignformat],
  data() {
    return {
      endpoint_get: 'https://pdhxfkl6gj.execute-api.ap-northeast-1.amazonaws.com/dev_cm_getAllUid/process',
      endpoint_get_region: 'https://wt3e3fzng1.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_post: 'https://6ccs283nk3.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      userTable: [],
      targetList: [],
      selectedTarget: "", 
      campaignList: [],
      selectedCampaign: "",
      clientList: [],
      client: '',
      budget: null,
      showTarget: false,
      showCampaign: false,
      showBudget: false,
      loading: false,
      ongoing: true,
      region: '',
    } 
  },
  methods: {
    campaignSelectItems() {
      const items = []
      for (let campaign of this.campaignList) {
        items.push({
          text: this.campaignformat(campaign),
          value: campaign
        })
      }
      return items
    },
    checked() {
      // チェックしているかどうか、表示ボタンを押せるようにする
      return this.selectedCampaign.length
    },
    register(){
      this.loading = true
      this.ongoing = false
      // TargetテーブルからCMデータを取得する
      axios.post(
        this.endpoint_post,
        JSON.stringify({
          target: this.selectedTarget,
          campaign: this.selectedCampaign,
          region: this.region,
          budget: this.budget,
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log(response.data)
      }).catch(error => console.log(error))
    },
    viewTarget(client){
      var target_array = []
      for(var i=0; i<this.userTable.length; i++){
        if (this.userTable[i].user_id == client) {
          target_array.push(this.userTable[i].target)
        }
      }
      this.targetList = target_array
      this.showTarget = true
      // 途中でクライアントを変更した時用
      this.showCampaign = false
    },
    viewCampaign(target){
      for(var i=0; i<this.userTable.length; i++){
        if (this.userTable[i].user_id==this.client && this.userTable[i].target==target) {
          var data = this.userTable[i]
          break
        }
      }
      this.selectedTarget = data.target
      data.campaign_list.sort()
      this.campaignList = data.campaign_list
      this.showCampaign = true

      // キャンペーンの選択を初期化する
      this.selectedCampaign = ""
    },
    reset() {
      this.client = ""
      this.selectedCampaign = ""
      this.selectedTarget = ""
      this.showCampaign = false
      this.showTarget = false
      this.selectedCampaign = ""
      this.ongoing = true
      this.selectedTarget = ""
      this.budget = null
      this.loading = false
    },
  },
  created(){
    // adminでなければ一般のページに遷移する
    if (this.$store.getters.user.username != "admin") {
        this.$router.push({ name: "Select" })
    }

    // Userテーブルから全データを取得する
    axios.get(
      this.endpoint_get
    ).then(response => {
      var userTable = response.data.body
      var clientList = []
      for(var i=0; i<userTable.length; i++){
        var user_id = userTable[i].user_id
        if (!clientList.includes(user_id)) {
          clientList.push(user_id)
      }
    }
    this.clientList = clientList
    this.userTable = userTable

    // Populationテーブルから全地域名を取得する
    axios.get(
      this.endpoint_get_region
    ).then(response => {
      this.regionList  = response.data.body
    }).catch(error => console.log(error))

  }).catch(error => console.log(error))
  },
}
</script>

<style scoped>
</style>