<template>
    <v-card
        class="mx-auto"
        elevation="6"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>


      <v-toolbar
        flat
        dense
        class="mb-n2"
      >
        <v-toolbar-title>時間帯</v-toolbar-title>

          <v-spacer/>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              to="time"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
            </v-btn>
            </template>
            <span>詳細を表示</span>
          </v-tooltip>

      </v-toolbar>

      <v-container>
          <v-row
            dense
            no-gutters
            class="ml-3"
          >
            <v-col cols="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div 
                    v-bind="attrs"
                    v-on="on"
                    class="caption grey--text font-weight-bold"
                  >
                    セッション効率
                  </div>
                </template>
                <span>セッション効率：視聴者100万人あたりの1分間の平均セッション増加数</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" class="mt-n2">
              <span class="text-caption">低</span>
            </v-col>
            <v-col cols="10">
              <v-card style="background:linear-gradient(to right, #ffd5ea, #d5006a); vertical-align: middle;" height="45%">
                &nbsp;
              </v-card>
            </v-col>
            <v-col cols="1" class="mt-n2">
              <span class="text-caption">高</span>
            </v-col>
          </v-row>

        <table v-if="!overlay">
          <thead>
            <tr>
              <th></th>
              <th v-for="dayOfWeek in dayOfWeekForDisplay" :key="dayOfWeek">
                {{ dayOfWeek }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hour in hourList()" :key="hour">
              <th>{{ hour }}</th>
              <td v-for="dayOfWeek in dayOfWeekForDisplay" :key="dayOfWeek">
                <span
                  v-if="effectDict[hour][dayOfWeek]"
                  :class="evaluation(effectDict[hour][dayOfWeek]['effect'])"
                >&nbsp;
                </span>
                <span
                  v-else
                  class="null"
                >&nbsp;
                </span>
              </td>
            </tr>
          </tbody>
        </table>

      </v-container>
    </v-card>
</template>

<script> 

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default {
  name: "TimeGraph",
  mixins: [calceffect,evaluation],
  data(){
    return{
      effectDict: {},
      dayOfWeekStrJP: ['日','月','火','水','木','金','土'],
      dayOfWeekForDisplay: ['月','火','水','木','金','土','日'],
      overlay: true,
    }
  },
  methods: {
    //時間の表示形式を整形
    timeDataShaping(timeFullList) {
      // 時間帯データは'hh:mm:ss'の文字列なので5〜28の文字列に変換する
      const timeCleanedList = []
      for (let time of timeFullList) {
        timeCleanedList.push(this.cleanTime(time))
      }
      return timeCleanedList
    },
    cleanTime(time) {
      let new_time = time.substr(0, time.indexOf(':'))
      new_time = Number(new_time)
      if (new_time <= 4) {
        new_time += 24
      }
      return new_time
    },
    // 効果の表示形式を整形
    effectDataShaping(timeCleanedList, effectFullList, dateFullList) {
      for(let hour of this.hourList()){
        let count = 0
        const effectList = []
        const dayOfWeekList = []
        for (let timeCleaned of timeCleanedList) {
          if (hour === timeCleaned) {
            effectList.push(effectFullList[count])
            let dateSplited = dateFullList[count].split('-')
            // 配列の文字列を数値に変換する
            dateSplited = dateSplited.map( str => parseInt(str, 10) )
            dateSplited[1] -= 1  // Dateオブジェクトは1~12月を0~11で表しているため修正
            let actualDate = new Date(dateSplited[0], dateSplited[1], dateSplited[2])
            //24時〜28時は実際の日付の前日としてカウント
            if (hour >= 24) {
              actualDate.setDate(actualDate.getDate() - 1)
            }
            dayOfWeekList.push(this.dayOfWeekStrJP[actualDate.getDay()])
          }
          count += 1
        }
        this.effectDict[hour] = this.calceffect(dayOfWeekList, effectList)
      }
    },
    hourList() {
      // 5~28を要素として持つ配列を作る
      let hourList = []
      for (var i=0; i<24; i++) {
        hourList.push(i+5)
      }
      return hourList
    },
    init() {
      const fullData = this.$store.getters.fullData
      const timeFullList = fullData.map(obj => obj.time)
      const timeCleanedList = this.timeDataShaping(timeFullList)
      const effectFullList = fullData.map(obj => obj.effect)
      const dateFullList = fullData.map(obj => obj.date)
      this.effectDataShaping(timeCleanedList, effectFullList, dateFullList)
      this.overlay = false
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: .8em;
}

tbody th {
  color: #ec6d88;
}
tbody td {
  border: 0.1px #fff solid;
}

.null{
  background-color: rgba(128, 128, 128, 0.1);
  display: block;
}

.bad{
  background-color: rgba(163, 158, 161, 0.7);
  display: block;
}

.normal{
  background-color: rgba(255,200,230,0.8);
  display: block;
}

.good{
  background-color:rgba(255,130,190,0.9);
  display: block;
}

.great{
  background-color: rgba(213,9,106,1);
  display: block;
}
</style>