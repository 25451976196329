<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  name: 'BarChart',
  props: [
    'labels', 'barData', 'selectedLabel'
  ],
  data () {
    return {
      backgroundSelectedColor: 'rgba(0, 119, 182, 0.6)',
      backgroundUnSelectedColor: 'rgba(100, 100, 100, 0.2)',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ]
      },
      options: {
        responive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
          padding: {
            left: -50,
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
            },
            categoryPercentage: 0.75,
          }],
          yAxes: [{
            gridLines: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                display: false,
                beginAtZero: true,
            }
          }]
        }
      }
    }
  },
  methods: {
    backgroundColor() {
      const colorList = []
      for (let label of this.labels) {
          if (label == this.selectedLabel) {
              colorList.push(this.backgroundSelectedColor)
          } else {
              colorList.push(this.backgroundUnSelectedColor)
          }                
      }
      return colorList
    },
    showGraph() {
      this.data.datasets[0].data = this.barData
      this.data.labels = this.labels
      this.data.datasets[0].backgroundColor = this.backgroundColor()
      this.renderChart(this.data, this.options)
    },
  },
  mounted () {
    this.showGraph()
  },
  // 選択されたラベルが変更されたらグラフを再描画する
  watch: {
    selectedLabel: function() {
      this.showGraph()
    },
    barData: function() {
      this.showGraph()
    },
  }
}
</script>