<template>
    <v-card
        elevation="6"
    >

        <v-overlay
          v-if="overlay"
          absolute
          opacity="0.1"
        >
          <v-progress-circular
              indeterminate
              color="#ec6d88"
          ></v-progress-circular>
        </v-overlay>
        
        <v-toolbar flat dense>
            <v-avatar color="#9B95C9" size="40" class="mt-1 ml-n1">
                <v-icon color="#583F99">
                    mdi-radio-tower
                </v-icon>
            </v-avatar>
            <v-toolbar-title class="text-subtitle-2 font-weight-bold mt-4 ml-2" style="color: gray;">局系列</v-toolbar-title>
            <v-spacer/>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon 
                    to="station"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="mt-3">mdi-arrow-top-right-bold-box-outline</v-icon>
                </v-btn>
                </template>
                <span>詳細を表示</span>
            </v-tooltip>
        </v-toolbar>

    <v-container fluid class="mt-n6">
        <v-row
            justify="center"
            align="center" 
        >
            <v-col cols="8">
                <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                    >
                        <span class="text-h5 font-weight-bold" style="color: black;">{{ station }}</span>
                        &nbsp;
                        <span style="font-size: .8em;">/</span>
                        &nbsp;
                        <span class="font-weight-bold" style="font-size: .9em;">{{ Object.keys(effectDict).length }}</span>
                        <span style="font-size: .7em;">系列</span>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item
                        v-for="station in stationListSortedByName()"
                        :key="station"
                        @click="changeStation(station)"
                    >
                        <v-list-item-title>{{ station }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
            </v-col>
            <v-col cols="4">
                <BarChart 
                    :barData="barData()"
                    :labels="stationListSorted"
                    :height="100"
                    :selectedLabel="station"
                />
            </v-col>
        </v-row>
    </v-container>

    <v-container>
    <v-row>
        <v-col>
            <v-row>
                <v-col class="feature-title" cols="12">
                    点数
                </v-col>
                <v-col class="feature-content" cols="12">
                    {{ effectDict[station]['effect'] }}
                </v-col>
            </v-row>
        </v-col>
        <v-col>
            <v-row>
                <v-col class="feature-title" cols="12">
                    放映回数
                </v-col>
                <v-col class="feature-content" cols="12">
                    {{ effectDict[station]['count'] }}
                </v-col>
            </v-row>
        </v-col>
        <v-col>
            <v-row>
                <v-col class="feature-title" cols="12">
                    評価
                </v-col>
                <v-col class="feature-content" cols="12">
                    <EvalIconDense :eval="evaluation(effectDict[station]['effect'])" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    </v-container>

    </v-card>
</template>

<script>
import BarChart from '@/components/common/modules/widgets/charts/BarChart.vue'
import EvalIconDense from '@/components/common/modules/generals/EvalIconDense.vue'

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default({
    name: "StationWidget",
    components: {
        BarChart,
        EvalIconDense
    },
    data(){
        return{
            overlay: true,
            effectDict: {},
            stationListSorted: [],
            station: '',
            stationOrderList: ['日テレ', '日本テレビ', 'テレビ朝日', 'TBS', 'フジテレビ', 'テレビ東京'],  // テレビ局はこの順番に表示する
        }
    },
    mixins: [calceffect, evaluation],
    methods: {
        changeStation(station) {
            this.station = station
        },
        createStationListSorted() {
            // セッション効率について降順で並べる
            this.stationListSorted = Object.keys(this.effectDict)
            this.stationListSorted.sort((a, b) => {
                return this.effectDict[b]['effect'] - this.effectDict[a]['effect']
            })
            this.station = this.stationListSorted[0]
        },
        barData() {
            const barData = []
            for (let station of this.stationListSorted) {
                barData.push(this.effectDict[station]['effect'])
            }
            return barData
        },
        ifBest() {
            return this.stationListSorted.indexOf(this.station) == 0
        },
        stationListSortedByName() {
        const listSorted = []
        for (let station of this.stationOrderList) {
            if (this.stationListSorted.includes(station)) {
            listSorted.push(station)
            }
        }
        return listSorted
        },
        init() {
            var fullData = this.$store.getters.fullData
            var stationFullList = fullData.map(obj => obj.station)
            var effectFullList = fullData.map(obj => obj.effect)
            this.effectDict = this.calceffect(stationFullList, effectFullList)
            this.createStationListSorted()
            this.overlay = false
        }
    },
    computed: {
        fullData() {
            return this.$store.getters.fullData
        },
    },
    watch: {
        fullData() {
            this.overlay = true
            this.init()
        }
    },
    created(){
        this.init()
    },
})
</script>

<style scoped>
::v-deep .v-select__selections input { width: 0}

.widget-title {
    color: #a7894b;
}

.feature-title {
    font-size: .5em;
    color: gray;
    margin: -2em 0;
    padding: 0;
}

.feature-content {
    font-size: 1em;
    margin-bottom: 1em;
    padding: 0;
}

::v-deep .v-btn {
  display: block !important;
  white-space: normal !important;
  overflow-wrap: anywhere !important;
}
</style>