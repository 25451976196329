<template>
  <div>

    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <CreativeWidget />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <StationWidget />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <RegionWidget />
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <DistributionWidget />
            </v-col>

            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <CampaignEffectWidget />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <ListWidget />
            </v-col>

          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="3"
            >
              <TimeWidget />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="5"
            >
              <ProgramWidget />
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <SelectWidget />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
// widgets
import CreativeWidget from "@/components/common/modules/widgets/CreativeWidget.vue"
import StationWidget from "@/components/common/modules/widgets/StationWidget.vue"
import RegionWidget from "@/components/common/modules/widgets/RegionWidget.vue"
import TimeWidget from "@/components/common/modules/widgets/TimeWidget.vue"
import ProgramWidget from "@/components/common/modules/widgets/ProgramWidget.vue"
import SelectWidget from "@/components/common/modules/widgets/SelectWidget.vue"
import DistributionWidget from "@/components/common/modules/widgets/DistributionWidget.vue"
import ListWidget from "@/components/common/modules/widgets/ListWidget.vue"
import CampaignEffectWidget from "@/components/common/modules/widgets/CampaignEffectWidget.vue"

export default {
  name: "AdminAnalysisDashboard",
  components: {
    CreativeWidget,
    StationWidget,
    RegionWidget,
    TimeWidget,
    ProgramWidget,
    SelectWidget,
    DistributionWidget,
    ListWidget,
    CampaignEffectWidget
  },
  created(){
    // データが存在しなければ選択画面に遷移する
    if (!this.$store.getters.fullData) {
        this.$router.push({ name: "AdminSelect" })
    }
  },
}
</script>