<template>
  <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
      <small>&copy; {{ new Date().getFullYear() }} T.M.</small>
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>