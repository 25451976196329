import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null, //サインイン済のユーザー情報を保持
        fullData: null, //当該userIdで得られるData全件
        fullDataArchive: null, //SelectedAnalysisで利用,fullDataを常に保持
        selectedRegionList: [], //SelectedAnalysisで利用
        selectedCreativeList: [], //SelectedAnalysisで利用
        selectedTimeList: [], //SelectedAnalysisで利用
        selectedStationList: [], //SelectedAnalysisで利用
        userData: [''], //当該userIdで得られるTarget,Campaignのリスト
        targets: [''], //target一覧(List)
        target: null, //指定中のtarget
        client: null, //指定中のtarget
        selectedCampaignList: [], //指定中のcampaignのリスト
        campaignList: [],  //campaign一覧(List)
        clientList: [],
        userTable: null,  // UserTableの全レコード、adminで用いる
        ifExtracted: false,  // データが選択されているかどうか,
        budgetList: [],
        regionList: [],  // 全地域
        selectedThemeDict: {},  // どのテーマが選択されたかを格納
        admin: false,  // adminかどうか
        username: '',  // ログイン情報に基づくユーザー名
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setUserData(state,userData){
            state.userData = userData;
            state.targets = userData.map(obj => obj.target);
            state.campaignList = [];
            for(var i=0; i<userData.length; i++){
                var c = userData.map((obj) => Object.assign({},obj));
                delete c[i].user_id;
                delete c[i].key_file;
                delete c[i].view_id;
                delete c[i].target;
                state.campaignList.push(c[i]);
            }
        },
        setUserTable(state, userTable){
            state.userTable = userTable
        },
        setClientList(state, clientList){
            state.clientList = clientList
        },
        setCampaignList(state, campaignList){
            state.campaignList = campaignList
        },
        setTarget(state,target){
            state.target = target
        },
        setClient(state, client){
            state.client = client
        },
        setSelectedCampaignList(state, selectedCampaignList){
            state.selectedCampaignList = selectedCampaignList
        },
        setFullData(state,fullData){
            state.fullData = fullData    
        },
        setFullDataArchive(state,fullDataArchive){
            state.fullDataArchive = fullDataArchive;
        },
        setSelectedRegionList(state, selectedRegionList){
            state.selectedRegionList = selectedRegionList
        },
        setSelectedCreativeList(state, selectedCreativeList){
            state.selectedCreative = selectedCreativeList
        },
        setSelectedTimeList(state, selectedTimeList){
            state.selectedTime = selectedTimeList
        },
        setSelectedStationList(state, selectedStationList){
            state.selectedStation = selectedStationList
        },
        setIfExtracted(state, boolean){
            state.ifExtracted = boolean
        },
        setBudgetList(state, budgetList) {
            state.budgetList = budgetList
        },
        setRegionList(state, regionList) {
            state.regionList = regionList
        },
        setSelectedThemeDict(state, selectedThemeDict) {
            state.selectedThemeDict = selectedThemeDict
        },
        setAdmin(state, boolean) {
            state.admin = boolean
        },
        setUsername(state, username) {
            state.username = username
        }
    },
    getters: {
        user(state){
            return state.user;
        },
        userData(state){
            return state.userData;
        },
        userTable(state){
            return state.userTable
        },
        clientList(state){
            return state.clientList
        },
        campaignList(state){
            return state.campaignList
        },
        target(state){
            return state.target;
        },
        client(state){
            return state.client;
        },
        selectedCampaignList(state){
            return state.selectedCampaignList
        },
        fullData(state){
            return state.fullData;
        },
        fullDataArchive(state){
            return state.fullDataArchive
        },
        selectedRegionList(state){
            return state.selectedRegionList
        },
        selectedCreativeList(state){
            return state.selectedCreativeList
        },
        selectedTimeList(state){
            return state.selectedTimeList
        },
        selectedStationList(state){
            return state.selectedStationList
        },
        targets(state){
            return state.targets;
        },
        ifExtracted(state){
            return state.ifExtracted
        },
        budgetList(state) {
            return state.budgetList
        },
        regionList(state) {
            return state.regionList
        },
        selectedThemeDict(state) {
            return state.selectedThemeDict
        },
        admin(state) {
            return state.admin
        },
        username(state) {
            return state.username
        },
    },
    //セッションストレージにデータを保存しておくことで、F5キーなどでページを更新しても分析結果が出力されるようにする
    plugins: [createPersistedState({storage: window.sessionStorage})]
});