<template>
  <v-navigation-drawer
    app
    clipped
    :mini-variant.sync="mini"
    mini-variant-width="45"
    width="260"
  >

    <v-list
      nav
      dense
    >
        <!-- キャンペーン変更 -->
        <v-list-group
          prepend-icon="mdi-alpha-c-circle-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>キャンペーン変更</v-list-item-title>
          </template>
          <div v-if="!mini">
            <v-list-item
              class="my-n2"
              v-for="campaign in this.$store.getters.campaignList"
              :key="campaign"
            >
              <v-list-item-title>
                <v-checkbox
                  v-model="selectedCampaignList"
                  :label="campaignformat(campaign)"
                  :value="campaign"
                  dense
                  style="transform:scale(0.9); font-size: 1.25em;"
                />
              </v-list-item-title>
            </v-list-item>
            <div>
              <v-btn
                v-if="selectedCampaignList.length"
                @click="changeCampaign()"
                dense
                :loading="loading"
                width="80%"
                elevation="1"
                color="#FFCB00"
              >表示
              </v-btn>
              <v-btn
                v-else
                disabled
                dense
                width="80%"

              >選択してください
              </v-btn>
            </div>
          </div>
        </v-list-group>

      <!-- ブランド変更 -->
      <v-list-item v-if="this.$store.getters.admin" :to="{name: 'AdminSelect'}">
        <v-list-item-icon>
          <v-icon>mdi-bullseye-arrow</v-icon>
        </v-list-item-icon>
        <v-list-item-title>ブランド変更</v-list-item-title>
      </v-list-item>

      <v-list-item v-else :to="{name: 'Select'}">
        <v-list-item-icon>
          <v-icon>mdi-bullseye-arrow</v-icon>
        </v-list-item-icon>
        <v-list-item-title>ブランド変更</v-list-item-title>
      </v-list-item>

    </v-list>
   

    <!-- Drawerの開閉 -->
    <template #append>
      <v-divider/>
      <div class="my-1">
        <v-btn
          v-if="mini"
          icon
          @click.stop="mini = !mini"
          style="margin: 0 0 0 auto;"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn 
          v-else
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script>
import axios from "axios"
import { campaignformat } from "@/components/mixins/campaignformat"

export default {
  name: 'Drawer',
  data(){
    return{
      endpoint_post: 'https://zd9s19kew9.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      mini: false,
      selectedCampaignList: [],
      showCampaign: false,
      showTarget: false,
      loading: false,
    }
  },
  mixins: [campaignformat],
  methods: {
    changeCampaign(){
      this.loading = true
      this.toAnalysis()
    },
    toAnalysis(){
      // TargetテーブルからCMデータを取得する
      axios.post(
        this.endpoint_post,
        JSON.stringify({
          target: this.$store.getters.target,
          campaign_list: this.selectedCampaignList,
          if_select_all: false
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        this.$store.commit('setSelectedCampaignList', this.selectedCampaignList)
        this.$store.commit('setFullData', response.data.body)
        this.$store.commit('setFullDataArchive', response.data.body)
        // データ選択は初期化する
        this.$store.commit('setIfExtracted', false)
        this.loading = false
        this.selectedCampaignList = []
      }).catch(error => console.log(error))
    },
    init() {
      const creativeFullList = this.$store.getters.fullDataArchive.map(obj => obj.creative)
      this.creativeList = Array.from(new Set(creativeFullList))
    }
  },
  computed: {
    fullDataArchive() {
      return this.$store.getters.fullDataArchive
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>