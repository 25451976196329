var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2"}},[(_vm.overlay)?_c('v-overlay',{attrs:{"absolute":"","opacity":"0.1"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#ec6d88"}})],1):_vm._e(),_c('Toolbar',{attrs:{"title":_vm.pageTitle,"jsonData":_vm.jsonData,"csvTitle":_vm.csvTitle}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":this.tableData(),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.eval",fn:function(ref){
var item = ref.item;
return [_c('EvalIconDense',{attrs:{"eval":item.eval}})]}}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("地域ごとの "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" セッション効率 ")])]}}])},[_c('span',[_vm._v("セッション効率：視聴者100万人あたりの1分間の平均セッション増加数")])])],1),_c('AnalysisBarChart',{attrs:{"barData":_vm.barData(),"labels":_vm.regionListSortedCleaned(),"height":250}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',[_c('v-card-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" セッション効率 ")])]}}])},[_c('span',[_vm._v("セッション効率：視聴者100万人あたりの1分間の平均セッション増加数")])]),_vm._v(" の分布 ")],1),_c('AnalysisHistogramChart',{attrs:{"histogramData":_vm.histogramData(),"startValue":_vm.startValue,"height":175}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"height":"100%","justify":"center"}},[_c('v-card-title',[_vm._v("放映回数")]),_c('AnalysisPieChart',{attrs:{"pieData":_vm.pieData(),"labels":_vm.regionListSortedCleaned(),"height":140,"styles":{'display':'flex', 'justify-content':'center'}}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v("CM一覧")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAllData,"items":this.$store.getters.fullData,"items-per-page":15,"sort-by":['region'],"multi-sort":"","dense":""}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('SelectWidget')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }