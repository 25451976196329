<template>
  <v-app>
    <v-main>
        <v-container fluid class="mt-4">
            <v-card
                class="mx-auto"
                max-width="1000"
            >

            <v-card-title>キャンペーンを終了・再開する</v-card-title>

            <v-container style="max-width:600px" class="mx-auto">

            </v-container>
            </v-card>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "EndCampaign",
  data() {
    return {
    }
  },
  created(){
  },
  methods: {
  },
}
</script>