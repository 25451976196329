<script>
import { Line } from 'vue-chartjs'

export default {
    extends: Line,
    props: [
        'labels', 'lineData', 'selectedLabelList'
    ],
    data () {
        return {
            data: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        borderColor: 'rgba(255,203,0,0.6)',
                        backgroundColor: 'rgba(120,120,120,0.2)',
                        lineTension: 0,
                    },
                ],
        },
        options: {
            legend: {
                display: false,
            },
            layout: {
                padding: {
                    left: 10,
                    right: 30,
                    top: 10,
                    bottom: 10,
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        display: false
                        // maxRotation: 90,
                        // minRotation: 90,
                        // fontSize: 9,
                        // callback: function(val){
                        //     if(val.length > 10){
                        //         return [val.substr(0, 10), val.substr(10)]
                        //     }else{
                        //         return val;                
                        //     }
                        // }
                    }
                }],
            }
        }
    }
  },
  methods: {
    pointColor() {
      const colorList = []
      for (let label of this.labels) {
          if (this.selectedLabelList.includes(label)) {
              colorList.push('rgba(255,20,100,1)')
          } else {
              colorList.push('rgba(255,213,20,0.8)')
          }                
      }
      return colorList
    },
    showGraph() {
        this.data.datasets[0].data = this.lineData
        this.data.labels = this.labels
        this.data.datasets[0].pointBorderColor = this.pointColor()
        this.data.datasets[0].pointBackgroundColor = this.pointColor()
        this.renderChart(this.data, this.options)
    },
  },
  mounted () {
    this.showGraph()
  },
  // 選択されたラベルが変更されたらグラフを再描画する
  watch: {
    lineData: function() {
      this.showGraph()
    },
    selectedLabelList: function() {
      this.showGraph()
    },
  }
}
</script>