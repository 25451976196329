<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="white">mdi-account</v-icon>
      </v-btn>
    </template>
    <amplify-sign-out/>
    
  </v-menu>
</template>

<script>
  export default {
    name: 'Account',
  }
</script>