<template>
  <v-app>
    <v-main>
        <v-container fluid class="mt-4">
            <v-card
                class="mx-auto"
                max-width="600"
            >

            <v-card-title>ブランド追加</v-card-title>

            <v-container style="max-width:500px" class="mx-auto">
              <v-row no-gutters>
                <v-col cols="12 text-left">
                  <span class="error text-subtitle-2">{{ target_error }}</span>
                  <v-text-field
                      v-model="target"
                      label="ブランド"
                      required
                  />
                </v-col>
                <v-col cols="12 text-left">
                  <span class="error text-subtitle-2">{{ user_error }}</span>
                  <v-text-field
                      v-model="user"
                      label="クライアント"
                      required
                  />
                </v-col>
                <v-col cols="12 text-left">
                  <span class="error text-subtitle-2">{{ view_id_error }}</span>
                  <v-text-field
                      v-model="view_id"
                      label="ビューID"
                      required
                  />
                </v-col>
                <v-col cols="12" class="my-3 py-1">
                  <div v-if="ongoing">
                    <v-btn
                      :loading="loading"
                      color="#FFCB00"
                      block
                      @click="register()">
                        登録する
                    </v-btn>
                  </div>

                  <div v-else>
                    <v-btn
                      color="#FFAAAA"
                      block
                      @click="reset()">
                        リセット
                    </v-btn>
                    <p v-if="alreadyRegistered" class="error">
                      すでにデータが存在しているため登録できませんでした
                    </p>
                    <p v-else>登録しました</p>
                  </div>
                </v-col>
              </v-row>



            </v-container>
            </v-card>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios"

export default {
  name: "AddTarget",
  data() {
    return {
      endpoint: 'https://qck3hqyozj.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      target: '',
      user: '',
      view_id: '',
      ongoing: true,
      loading: false,
      target_error: '',
      user_error: '',
      view_id_error: '',
      alreadyRegistered: false,
    }
  },
  created(){
  },
  methods: {
    register() {
      this.loading = true
      if (this.inputValidate()) {
        this.writeDB()
        this.ongoing = false
      }
      this.loading = false
    },
    inputValidate() {
      // 入力をチェックする
      var validation = true

      if (this.target == '') {
        this.target_error = "入力してください", validation=false
      } else {
        this.target_error = ''
      }

      if (this.user == '') {
        this.user_error = "入力してください", validation=false
      } else {
        this.user_error = ''
      }

      if (this.view_id == '') {
        this.view_id_error = "入力してください", validation=false
      } else {
        this.view_id_error = ''
      }

      return validation
    },
    hankaku2Zenkaku(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
        })
    },
    writeDB() {
      axios.post(
        this.endpoint,
        JSON.stringify({
          user_id: this.user,
          target: this.target,
          view_id: this.hankaku2Zenkaku(this.view_id),
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log(response.data)
        // すでにデータが存在した場合nullとなる
        if (!response.data) {
          this.alreadyRegistered = true
        }
      }).catch(error => console.log(error))
    },
    reset() {
      this.target= ''
      this.user= ''
      this.view_id= ''
      this.ongoing = true
      this.loading = false
      this.alreadyRegistered = false
    }
  },
}
</script>