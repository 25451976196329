<template>
  <v-app>
    <Header/>
    <router-view/>
  </v-app>
</template>

<script>
import Header from "@/components/common/globals/Header.vue"

export default {
  name: "AdminIndex",
  components: {
    Header,
  },
  data() {
    return {
    }
  },
  created(){
  },
  methods: {
  },
}
</script>