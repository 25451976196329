<template>
  <v-app>
    <v-main>
        <v-container fluid class="mt-4">
          <v-card
            class="mx-auto"
            max-width="1400"
          >

            <v-card-title>テレビ動画登録</v-card-title>

            <v-container style="max-width:1200px" class="mx-auto">

            <!-- 開始時刻 -->
            <v-row
              class="my-n8"
              align="center"
            >
              <v-col cols="1">
                <h2>開始時刻</h2>
                <span class="error">{{ time_error }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-text-field
                  v-model="date"
                  label="年月日（例：2021/02/21）"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="time"
                  :counter="4"
                  maxlength="4"
                  label="時分（例：0623）"
                />
              </v-col>
            </v-row>

            <!-- 地域 -->
            <v-row
              class="my-n8"
              align="center"
            >
              <v-col cols="1">
                <h2>地域</h2>
                <span class="error">{{ region_error }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="11">
                <v-radio-group v-model="region" row>
                <v-radio
                  v-for="n in region_list"
                  color="indigo"
                  :key="n"
                  :label="n"
                  :value="n"/>
                </v-radio-group>
              </v-col>
            </v-row>

            <!-- 局 -->
            <v-row
              class="my-n8"
              align="center"
            >
              <v-col cols="1">
                <h2>局</h2>
                <span class="error">{{ station_error }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="11">
                <v-radio-group v-model="station" row>
                <v-radio
                  v-for="n in station_list"
                  color="indigo"
                  :key="n"
                  :label="n"
                  :value="n"/>
                </v-radio-group>
              </v-col>
            </v-row>

            <!-- 番組名 -->
            <v-row
              class="my-n8"
              align="center"
            >
              <v-col cols="1">
                <h2>番組</h2>
                <span class="error">{{ program_error }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <v-autocomplete
                  v-if="found_program"
                  v-model="program"
                  :items="program_list"
                  hide-selected
                  return-object
                  placeholder="番組名"
                />
                <v-text-field
                  v-else
                  v-model="program"
                  label="番組名"
                  required
                />
              </v-col>
              <v-col cols="2" class="mb-2">
                <v-btn
                  v-if="found_program"
                  class="mx-2"
                  dark
                  small
                  color="#888"
                  @click="found_program=false"
                ><span style="text-transform: none;">No Data Available</span>
                </v-btn>
                <v-btn
                  v-else
                  class="mx-2"
                  dark
                  small
                  color="#888"
                  @click="found_program=true"
                >検索する
                </v-btn>
            </v-col>
            </v-row>

            <!-- GRP -->
            <v-row
              class="my-n8"
              align="center"
            >
              <v-col cols="1">
                <h2>GRP</h2>
                <span class="error">{{ grp_error }}</span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col
                md="2"
              >
                <v-text-field
                  v-model="grp"
                  label="小数点1桁"
                  type="number"
                  step="0.1"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- クリエイティブ -->
            <v-card
              class="mx-auto mt-8 mb-6"
              max-width="1200"
            >
              <v-card-title>
                <h2>クリエイティブ</h2>
                <!-- クリエイティブ追加用のボタン -->
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  absolute
                  right
                  color="#ec6d88"
                  @click="addCreative"
                >追加
                </v-btn>
              </v-card-title>

              <div>
                <div class='creative-item ml-4 mr-4' v-for="(item, index) in creative_list" :key='item.id'>
                  <v-row
                    class="pt-2 mb-n6"
                    cols="12"
                  >

                    <!-- 10桁コード -->
                    <v-col
                      md="3"
                    >
                      <v-text-field
                        v-model="item.creative_id"
                        label="10桁コード"
                        :counter="11"
                        required
                      />
                      <span class="error">{{ item.creative_id_error }}</span>
                    </v-col>

                    <!-- キャンペーン -->
                    <v-col
                      md="3"
                    >
                      <v-autocomplete
                        v-model="item.campaign"
                        :items="campaign_list"
                        return-object
                        placeholder="キャンペーンID"
                      />
                      <span class="error">{{ item.campaign_error }}</span>
                    </v-col>

                    <!-- 枠区分 -->
                    <v-col
                      md="2"
                    >
                      <v-select
                        :items="slot_list"
                        v-model="item.slot"
                        label="枠区分"
                      ></v-select>
                      <span class="error">{{ item.slot_error }}</span>
                    </v-col>

                    <!-- 回数 -->
                    <v-col
                      md="2"
                    >
                      <v-text-field
                        v-model="item.count"
                        type="number"
                        label="回数"
                        required
                      />
                      <span class="error">{{ item.count_error }}</span>
                    </v-col>

                    <!-- カップリング -->
                    <v-col
                      md="2"
                    >
                      <v-select
                        :items="coupling_list"
                        v-model="item.coupling"
                        label="カップリング"
                      ></v-select>
                      <span class="error">{{ item.coupling_error }}</span>
                    </v-col>
                
                    <!-- クリエイティブ削除用のボタン -->
                    <div
                      v-if="creative_list.length > 1"
                    >
                      <v-btn
                        class="mx-2"
                        dark
                        absolute
                        small
                        right
                        color="primary"
                        @click="deleteCreative(index)"
                      >削除
                      </v-btn>
                    </div>
                  </v-row>
                </div>
              </div>
            </v-card>

            <v-container>
              <div v-if="!video_id" class="pb-8">
                <v-btn
                  block
                  color="#FFCB00"
                  @click="generateId"
                >完了
                </v-btn>
              </div>

              <div v-else>
                <v-btn
                  class="mt-5"
                  block
                  color="#AABBFF"
                  @click="copyToClipboard"
                >
                  ファイル名：<b style="text-transform: none;">{{ video_id }}</b>
                  <v-icon>mdi-paperclip</v-icon>
                  <span v-if="copied">コピーしました</span>
                  <span v-else>クリックでコピー</span>
                </v-btn>
                <v-btn
                  class="mt-5"
                  color="#FFAAAA"
                  @click="reset"
                  tile
                >リセット
                </v-btn>
              </div>
            </v-container>
            
            </v-container>
          </v-card>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios"

export default {
  name: "TvIdGenerate",
  data() {
    return {
      endpoint: 'https://aae6hou3ka.execute-api.ap-northeast-1.amazonaws.com/first_stage/process',
      endpoint_scan_campaign: 'https://o9w2gy6hxd.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      endpoint_scan_program: 'https://emlm61iybl.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      date: '',
      time: '',
      region: '関東',
      station: '',
      program: '',
      creative_list: [{
        creative_id: "", campaign: '', slot: 'PT', count: '1', coupling: "なし", 
        creative_id_error: '', count_error: '', coupling_error: "", campaign_error: '', slot_error: ''
      }],
      video_id: '',
      grp: '',

      found_program: true,

      isEdit: false,

      // 新しく変数を増やすときは・writeDB()・reset()・addCreative()を変更する必要がある
      // creative_listに追加するときはarrangeCreativeList()も要変更

      time_error: '',
      region_error: '',
      program_error: '',
      station_error: '',
      grp_error: '',

      region_list: ['関東', '関西', '中京', '北海道', '宮城県', '新潟県', '石川県', '静岡県', '香川県・岡山県', '広島県', '福岡県'],
      station_list: ['日本テレビ', 'テレビ朝日', 'TBS', 'フジテレビ', 'テレビ東京'],
      coupling_list: ['なし', '前', '後'],
      // programテーブルから番組名を取得する
      program_list: [],
      campaign_list: [],
      slot_list: ['PT', 'SB', 'TIME'],

      copied: false,
    }
  },
  created(){
    this.getCampaignTable()
    this.getProgramTable()
  },
  methods: {
    getCampaignTable() {
      axios.get(
        this.endpoint_scan_campaign
      ).then(response => {
        this.campaign_list = this.makeCampaignList(response.data.body)
      }).catch(error => console.log(error))
    },
    getProgramTable() {
      axios.get(
        this.endpoint_scan_program
      ).then(response => {
        this.program_list = this.makeProgramList(response.data.body)
      }).catch(error => console.log(error))
    },
    makeCampaignList(dict_list){
      var campaign_list = []
      for (var item of dict_list) {
        var target = item.target
        var campaign = item.campaign
        campaign_list.push(target+" "+campaign)
      }
      return campaign_list
    },
    makeProgramList(dict_list){
      var program_list = []
      for (var item of dict_list) {
        program_list.push(item.name)
      }
      return program_list
    },
    getUniqueStr(myStrong){
      // uuidを生成する
      var strong = 1000;
      if (myStrong) strong = myStrong;
      return new Date().getTime().toString(16)  + Math.floor(strong*Math.random()).toString(16)
    },
    generateId() {
      // 入力が適切な場合動画idを生成し、DynamoDBに書き込む
      this.errorInit()  // error文を初期化
      var validation = this.inputValidate()  // 入力をチェックする
      if (validation) {
        var post_creative_list = this.arrangeCreativeList()
        this.video_id = this.getUniqueStr()  // uuidを生成する
        this.writeDB(post_creative_list)
      }
    },
    arrangeCreativeList() {
      // ポスト用にcreative_listを整える
      var post_creative_list = []
      for (var creative of this.creative_list) {
        var coupling = ""
        // カップリングの表記を整える
        if (creative.coupling=="なし"){
          coupling = "none"
        }else if (creative.coupling=="前") {
          coupling = "before"
        } else if (creative.coupling=="後"){
          coupling = "after"
        }
        var element = {
          'creative_id': this.han2zen(creative.creative_id),
          'count': creative.count,
          'coupling': coupling,
          'campaign': creative.campaign.split(' ')[1],
          'slot': creative.slot
        }
        post_creative_list.push(element)
      }
      return post_creative_list
    },
    writeDB(post_creative_list) {
      // RegisterVideoテーブルに書き込むために、API gatewayを通してLambdaにPOSTする
      // dateはyyyy/mm/ddの形式になっているので、不必要な文字列を削除する
      var yyyymmdd = this.yyyymmdd(this.han2zen(this.date))

      // 関西を近畿に修正する
      var region = this.region
      if (this.region == '関西') {
        region = '近畿'
      }
      
      axios.post(
        this.endpoint,
        JSON.stringify({
          video_id: this.video_id,
          date: yyyymmdd,
          time: this.han2zen(this.time),
          region: region,
          station: this.station,
          program: this.program,
          grp: this.grp,
          creative_list: post_creative_list
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log(response.data);
      }).catch(error => console.log(error));
    },
    errorInit() {
      // error文を初期化
      this.time_error = ''
      this.region_error = ''
      this.station_error = ''
      this.program_error = ''
      for (const creative of this.creative_list) {
        creative.creative_id_error = ""
        creative.count_error = ""
        creative.coupling_error = ""
        creative.campaign_error = ""
        creative.slot_error = ""
      }
    },
    inputValidate() {
      // 入力をチェックする
      var validation = true

      if (this.date == '') {this.time_error = "入力してください", validation=false}
      else if (this.time == '') {this.time_error = "入力してください", validation=false}
      else if (this.time.length != 4) {this.time_error = "時分は4桁で入力してください", validation=false}
      else if(this.han2zen(this.date).indexOf('/') == -1) {this.time_error = "年月日はスラッシュで分割してください", validation=false}
      
      if (this.region == '') {this.region_error = "入力してください", validation=false}
      if (this.station == '') {this.station_error = "入力してください", validation=false}
      if (this.program == '') {this.program_error = "入力してください", validation=false}

      // GRP
      var grp_length = String(this.grp).length
      if (grp_length == 0) {this.grp_error="入力してください", validation=false}
      else if (grp_length == 1) {console.log('')}
      else if (String(this.grp).substr(grp_length-2, 1)!=".") {this.grp_error="小数点第一位までを入力してください", validation=false}

      for (const creative of this.creative_list) {
        // 10桁id
        if (creative.creative_id == '') {creative.creative_id_error="入力してください", validation=false}
        else if (creative.creative_id.length != 11) {creative.creative_id_error="11桁で入力してください", validation=false}
        // キャンペーンid
        if (creative.campaign == '') {creative.campaign_error="入力してください", validation=false}
        // CMの回数
        if (creative.count == '') {creative.count_error="入力してください", validation=false}
      }
      
      return validation
    },
    han2zen(str) {
      var regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g
      // 入力値の全角を半角の文字に置換
      var value = str.replace(regex, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
        })
        .replace(/[‐－―]/g, "-") // ハイフンなど
        .replace(/[～〜]/g, "~") // チルダ
      return value
    },
    addCreative() {
      const additionalCreative = {
        creative_id: "",
        count: '1',
        coupling: "なし",
        slot: "PT",
        creative_id_error: '',
        count_error: '',
        coupling_error: '',
        slot_error: '',
      }
      this.creative_list.push(additionalCreative);
    },
    deleteCreative(index) {
      this.creative_list.splice(index, 1)
    },
    copyToClipboard() {
      this.copied = true
      navigator.clipboard.writeText(this.video_id)
      .then(() => {
          console.log("copied!")
      })
      .catch(e => {
          console.error(e)
      })
    },
    reset() {
      // 初期化する
      this.time = '',
      this.region = '関東',
      this.station = '',
      this.program = '',
      this.grp = '',
      this.creative_list = [
        {creative_id: "", count: '1', campaign: '', coupling: 'なし', slot: 'PT',
        creative_id_error: '', count_error: '', campaign_error: '', slot_error: ''}
      ],
      this.video_id = '',  // video_idを''にすることで送信ボタンを復活させる
      this.grp_error = '',
      this.time_error = '',
      this.region_error = '',
      this.program_error = '',
      this.station_error = ''
    },
    formatToDateString() {
      // 空文字の場合、変換しない
      if (this.inputDate === '') return
      // 形式が正しくない場合、変換しない
      if (!this.dateRule.test(this.inputDate)) return
      
      const str = String(this.inputDate)
      // 表示用に加工
      this.displayDate = `${str.slice(0, 4)}/${str.slice(4, 6)}/${str.slice(6,8)}`
      // DB保存用に加工
      this.outputDate = `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6,8)}`
      this.isEdit = false
    },
    // 1桁の数字を0埋めで2桁にする
    toDoubleDigits(num) {
      num += "";
      if (num.length === 1) {
        num = "0" + num
      }
    return num
    },
    // 日付をYYYYMMDD形式で取得
    yyyymmdd(date) {
      var date_splited = date.split('/')
      var yyyy = date_splited[0]
      var mm = this.toDoubleDigits(date_splited[1])
      var dd = this.toDoubleDigits(date_splited[2])
      return yyyy + mm + dd
    },
  },
}
</script>


<style scoped>
h1 {
  font-size: 20px;
}
h2 {
  display: inline-block;
  font-size: 18px;
  color: #1E90FF;
}
.error {
  font-size: 15px;
}
</style>