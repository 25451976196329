<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from "@/components/common/modules/generals/Footer.vue"
export default {
  name: 'App',
  components: {
    Footer
  },
}
</script>

<style>
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Noto Sans Japanese', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f5;
  text-transform: none;
}

a {
  text-decoration: none;
}
</style>