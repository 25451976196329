<template>
  <v-app>
    <v-main>
        <v-container fluid class="mt-4">
            <v-card
                class="mx-auto"
                max-width="1000"
            >

            <v-card-title>キャンペーン追加</v-card-title>

            <v-container style="max-width:600px" class="mx-auto">

                <v-select
                :items="target_list"
                v-model="target"
                label="ブランド"
                ></v-select>
                <span class="error">{{ target_error }}</span>

                <v-text-field
                    v-model="campaign"
                    label="キャンペーン"
                    :counter="16"
                    required
                    dense
                />
                <span class="error">{{ campaign_error }}</span>

                <div v-if="ongoing">
                    <v-btn
                    :loading="loading"
                    color="#FFCB00"
                    class="mb-5 px-8 py-2"
                    @click="register()">
                        登録する
                    </v-btn>
                </div>

                <div v-else>
                    <v-btn
                    color="#FFAAAA"
                    class="mb-5 px-8 py-2"
                    @click="reset()">
                        リセット
                    </v-btn>
                    <p>
                      登録しました
                     </p> 
                </div>

            </v-container>
            </v-card>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios"

export default {
  name: "AddCampaign",
  data() {
    return {
        endpoint_scan_user: 'https://rf6kyc3fm2.execute-api.ap-northeast-1.amazonaws.com/first_stage',
        endpoint_post: 'https://w3x7f4u492.execute-api.ap-northeast-1.amazonaws.com/first_stage',
        target: '',
        campaign: '',
        ongoing: true,
        loading: false,
        target_list: [],
        target_error: '',
        campaign_error: '',
    }
  },
  created(){
      axios.get(
        this.endpoint_scan_user
      ).then(response => {
        this.target_list = this.makeTargetList(response.data.body)
      }).catch(error => console.log(error))
  },
  methods: {
    makeTargetList(dict_list){
      var target_list = []
      for (var item of dict_list) {
        var target = item.target
        target_list.push(target)
      }
      return target_list
    },
    register(){
      this.loading = true
      if (this.inputValidate()) {
        this.writeDB()
        this.ongoing = false
      }
      this.loading = false
    },
    inputValidate() {
      // 入力をチェックする
      var validation = true

      if (this.target == '') {
        this.target_error = "入力してください", validation=false
      } else {
        this.target_error = ''
      }

      if (this.campaign == '') {
        this.campaign_error = "入力してください", validation=false
      } else if (this.campaign.length != 16) {
        this.campaign_error = "16桁で入力してください", validation=false
      } else {
        this.campaign_error = ''
      }

      return validation
    },
    writeDB() {      
      axios.post(
        this.endpoint_post,
        JSON.stringify({
          campaign: this.campaign,
          target: this.target,
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log(response.data);
      }).catch(error => console.log(error));
    },
    reset() {
      this.target = null
      this.campaign = ''
      this.ongoing = true
      this.loading = false
    }
  },
}
</script>