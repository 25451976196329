<template>
  <v-container fluid>
    <v-card
      class="mb-4"
      elevation="6"
    >

      <v-overlay
        v-if="overlay"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
            indeterminate
            color="#ec6d88"
        ></v-progress-circular>
      </v-overlay>

      <Toolbar :title="pageTitle" :jsonData="jsonData" :csvTitle="csvTitle" />

      <div>
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                text
            >
              <span class="text-h5 font-weight-bold">{{ station }}</span>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item
                v-for="station in stationList"
                :key="station"
                @click="changeStation(station)"
            >
                <v-list-item-title>{{ station }}</v-list-item-title>
            </v-list-item>
        </v-list>
        </v-menu>
      </div>

      <v-container>
        <v-row>
          <v-col cols="3" v-for="evalValue in evalList" :key="evalValue">
            <v-row>
              <v-col cols="12">
                <EvalIcon :eval="evalValue" />
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="tableData(evalValue)"
                  class="elevation-1"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                />          
              </v-col>
            </v-row>
          </v-col>
        </v-row>


      </v-container>
    </v-card>

    <v-container>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title>CM一覧</v-card-title>
            <v-data-table
              :headers="headersAllData"
              :items="this.$store.getters.fullData"
              :items-per-page="15"
              class="elevation-1"
              :sort-by="['program']"
              multi-sort
              dense
            />
          </v-card>
        </v-col>

        <v-col cols="4">
          <SelectWidget />
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script> 
import EvalIcon from '@/components/common/modules/generals/EvalIcon.vue'
import Toolbar from '@/components/common/modules/generals/Toolbar.vue'
import SelectWidget from "@/components/common/modules/widgets/SelectWidget.vue"

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default {
  name: "ProgramWidget",
  mixins: [calceffect, evaluation],
  components: { 
    EvalIcon,
    Toolbar,
    SelectWidget,
  },
  data(){
    return{
      pageTitle: '番組',
      effectDictByStationEval: {},
      station: "",
      stationList: [],
      effect_JSON: [],
      jsonData: [],
      csvTitle: "program",
      CSV_title: "ProgramGraph",
      headers: [
        { text: '番組名', align: 'start', value: 'program'},
        // { text: '点数', value: 'effect'},
        { text: '回数', value: 'count'},
      ],
      headersAllData: [
        { text: '番組名', align: 'start', value: 'program' },
        { text: '日時', value: 'date'},
        { text: '局系列', value: 'station' },
        { text: 'クリエイティブ', value: 'creative' },
        { text: '地域', value: 'region' },
        { text: '点数', value: 'effect' },
        { text: 'GRP', value: 'grp' },
        { text: '放映時刻', value: 'time' },
        // { text: '枠区分', value: 'slot' },
        // { text: 'カップリング', value: 'coupling' },
      ],
      overlay: true,
      selectedEvalIndex: 0,
      evalList: ['great', 'good', 'normal', 'bad'],
      stationOrderList: ['日テレ', '日本テレビ', 'テレビ朝日', 'TBS', 'フジテレビ', 'テレビ東京'],
    }
  },
  methods: {
    stationListSorted(stationList) {
      const listSorted = []
      for (let station of this.stationOrderList) {
        if (stationList.includes(station)) {
          listSorted.push(station)
        }
      }
      return listSorted
    },
    tableData(evalValue) {
      // v-data-tableに渡すデータ
      return this.effectDictByStationEval[this.station][evalValue]
    },
    changeStation(value) {
      this.station = value
    },
    effectDataShaping(stationFullList, programFullList, effectFullList) {
      // 局ごとにデータを格納する
      for(let station of this.stationList){
        let count = 0
        const effectList = []
        const programList = []
        for (let stationName of stationFullList) {
          if (station === stationName) {
            effectList.push(effectFullList[count])
            programList.push(programFullList[count])
          }
          count += 1
        }
        const effectDict = this.calceffect(programList, effectList)
        this.storeEffectDictForEachEval(station, effectDict)
      }
    },
    storeEffectDictForEachEval(station, effectDict) {
      // 4段階評価を加え、局系列別かつ評価別にデータを格納する
      // 評価ごとにデータを格納する連想配列を初期化する
      const evalListArray = {}
      for (let evalName of this.evalList) {
        evalListArray[evalName] = []
      }
      for (let key of Object.keys(effectDict)) {
        let evalValue = this.evaluation(effectDict[key]['effect'])
        effectDict[key]['eval'] = evalValue
        const dictElement = {'program': key, 'count': effectDict[key]['count'], 'effect': effectDict[key]['effect']}
        evalListArray[evalValue].push(dictElement)
      }
      for (let key of Object.keys(evalListArray)) {
        // countについて昇順にソートする
        evalListArray[key].sort((first, second) => {
          return second['count'] - first['count']
        })
      }
      // 局系列別にデータを格納する
      this.effectDictByStationEval[station] = evalListArray
    },      
    //CSV出力用にデータを整形
    makeEffectJSON(){
      this.jsonData = []
      let dictElement = {}
      for (let key of Object.keys(this.effectDictByStationEval)){
        for (let evalValue of this.evalList) {
          dictElement[`${key}_${evalValue}`] = []
          for (let program of this.effectDictByStationEval[key][evalValue]) {
            dictElement[`${key}_${evalValue}`].push(program['program'])
          }
        }
      }
      this.jsonData.push(dictElement)
    },
    init() {
      const fullData = this.$store.getters.fullData
      const stationFullList = fullData.map(obj => obj.station)
      const effectFullList = fullData.map(obj => obj.effect)
      const programFullList = fullData.map(obj => obj.program)
      // 局系列のユニークな値を抽出する
      this.stationList = Array.from(new Set(stationFullList))

      this.effectDataShaping(stationFullList, programFullList, effectFullList)
      this.stationList = this.stationListSorted(this.stationList)
      this.station = this.stationList[0]
      this.makeEffectJSON()  // CSV用のデータ整形
      this.overlay = false
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>


<style scoped>
table {
  border-collapse: collapse;
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  width: 100%;
  table-layout: fixed;
  color: #470000;
  text-align: center;
  font-size: .8em;
}

table tr {
  background-color: #fff;
  padding: .35em;
  border-bottom: 1px dotted #ec6d88;
}

thead tr:first-child {
  border-bottom: #fff;
}


table th,
table td {
  padding: .5em;
}
.pointer{
  cursor: default;
}
table tr:last-child{
   border-bottom: 2px solid #bf0017;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
    width:100%
  }
  table th{
    display: block;
    border-right: none;
    border-bottom: 2px solid #ec6d88;
    padding-bottom: .6em;
    margin-bottom: .6em;

  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: 2em;
    border-bottom: 2px solid #ec6d88;
  }

  table td:last-child {
    display: block;
    margin-bottom: 2em;
        border-bottom: 2px solid #ec6d88;
  }
  
  table td {
    display: block;
    font-size: .8em;
    text-align: right;
    position: relative;
    padding: .625em .625em .625em 4em;
    border-right: none;
  }
  
  table td::before {
    content: attr(data-label);
    font-weight: bold;
    position: absolute;
    left: 10px;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

</style>