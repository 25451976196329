<template>
  <v-card
      elevation="6"
  >

        <v-overlay
          v-if="overlay"
          absolute
          opacity="0.1"
        >
          <v-progress-circular
              indeterminate
              color="#ec6d88"
          ></v-progress-circular>
        </v-overlay>

        <v-toolbar flat dense>
            <v-avatar color="#A2DAFF" size="40" class="mt-1 ml-n1">
                <v-icon color="#1F82CD">
                    mdi-lightbulb-on-outline
                </v-icon>
            </v-avatar>
            <v-toolbar-title class="text-subtitle-2 font-weight-bold mt-4 ml-2" style="color: gray;">CM一覧</v-toolbar-title>
            <v-spacer/>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon 
                    to="list"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="mt-3">mdi-arrow-top-right-bold-box-outline</v-icon>
                </v-btn>
                </template>
                <span>詳細を表示</span>
            </v-tooltip>
        </v-toolbar>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="cmListItems"
              :items-per-page="4"
              class="elevation-1"
              dense
              disable-sort
              hide-default-footer
            />
          </v-col>
        </v-row>

  </v-card>
</template>

<script>
export default {
  name: "ListWidget",
  data() {
    return {
      search: '',
      jsonData: [],
      cmListItems: [],
      overlay: true,
      headers: [
        { text: '日時', align: 'start', value: 'date' },
        { text: '放映時刻', value: 'time' },
        { text: '番組名', value: 'program' },
        { text: '点数', value: 'effect' },
      ],
    }
  },
  methods: {
    setCMListItems() {
      let cmItems = JSON.parse(JSON.stringify(this.$store.getters.fullData))

      // セッション効率の良い4つを抽出する
      cmItems.sort((a, b) => {
          return b['effect'] - a['effect']
      })

      let cmItemsExtracted = cmItems.slice(0, 4)

      // 数値データを小数点1桁で0埋めする
      // 日付を月日だけにする
      for (let item of cmItemsExtracted) {
        item['effect'] = Number(item['effect']).toFixed(1)
        item['date'] = item['date'].slice(5,7) + '/' + item['date'].slice(8,10)
      }
      this.cmListItems = cmItemsExtracted
    },
    init() {
        this.setCMListItems()
        this.overlay = false
    }
  },
  computed: {
    fullData() {
      return this.$store.getters.fullData
    },
  },
  watch: {
    fullData() {
      this.overlay = true
      this.init()
    }
  },
  created(){
    this.init()
  },
}
</script>