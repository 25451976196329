<template>
    <v-card
        elevation="6"
    >

        <v-overlay
          v-if="overlay"
          absolute
          opacity="0.1"
        >
          <v-progress-circular
              indeterminate
              color="#ec6d88"
          ></v-progress-circular>
        </v-overlay>

        <v-toolbar flat dense>
            <v-avatar color="#FFDAA2" size="40" class="mt-1 ml-n1">
                <v-icon color="#CD821F">
                    mdi-head-lightbulb-outline
                </v-icon>
            </v-avatar>
            <v-toolbar-title class="text-subtitle-2 font-weight-bold mt-4 ml-2" style="color: gray;">クリエイティブ</v-toolbar-title>
            <v-spacer/>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    icon 
                    to="creative"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="mt-3">mdi-arrow-top-right-bold-box-outline</v-icon>
                </v-btn>
                </template>
                <span>詳細を表示</span>
            </v-tooltip>
        </v-toolbar>

        <v-container fluid class="mt-n6">
            <v-row
                justify="center"
                align="center" 
            >
                <v-col cols="8">
                    <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="height: 100%;"
                        >
                            <span class="font-weight-bold text-subtitle-2" style="color: black;">{{ creative }}</span>
                            &nbsp;
                            <span style="font-size: .8em;">/</span>
                            &nbsp;
                            <span class="font-weight-bold" style="font-size: .9em;">{{ Object.keys(effectDict).length }}</span>
                            <!-- 縦書きにならないように分けて書いた -->
                            <span style="font-size: .7em;">素</span>
                            <span style="font-size: .7em;">材</span>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item
                            v-for="creative in creativeListSorted"
                            :key="creative"
                            @click="changeCreative(creative)"
                        >
                            <v-list-item-title>{{ creative }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <BarChart 
                        :barData="barData()"
                        :labels="creativeListSorted"
                        :height="100"
                        :selectedLabel="creative"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container>
        <v-row>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        点数
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        {{ effectDict[creative]['effect'] }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        放映回数
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        {{ effectDict[creative]['count'] }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="feature-title" cols="12">
                        評価
                    </v-col>
                    <v-col class="feature-content" cols="12">
                        <EvalIconDense :eval="evaluation(effectDict[creative]['effect'])" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
import BarChart from '@/components/common/modules/widgets/charts/BarChart.vue'
import EvalIconDense from '@/components/common/modules/generals/EvalIconDense.vue'

import { calceffect } from "@/components/mixins/calceffect"
import { evaluation } from "@/components/mixins/evaluation"

export default({
    name: "CreativeWidget",
    components: {
        BarChart,
        EvalIconDense
    },
    data(){
        return{
        overlay: false,
        effectDict: {},
        creative: '',
        creativeListSorted: [],
        }
    },
    mixins: [calceffect, evaluation],
    methods: {
        changeCreative(creative) {
            this.creative = creative
        },
        // imageKeyはクリエイティブの名称
        imagePath(imageKey) {
        var creative_id = this.findCreativeId(imageKey)
        return creative_id + '.png'
        },
        // クリエイティブの名称をキーとしてtargetテーブルの全データから10桁コードを取得する
        findCreativeId(imageKey) {
        var fullData = this.$store.getters.fullData
        for (var i=0; i<fullData.length; i++) {
            if (fullData[i].creative == imageKey) {
            return fullData[i].creative_id
            }
        }
        throw 'cannot find creative ID'
        },
        createCreativeListSorted() {
            // セッション効率について降順で並べる
            this.creativeListSorted = Object.keys(this.effectDict)
            this.creativeListSorted.sort((a, b) => {
                return this.effectDict[b]['effect'] - this.effectDict[a]['effect']
            })
            this.creative = this.creativeListSorted[0]
        },
        barData() {
            const barData = []
            for (let creative of this.creativeListSorted) {
                barData.push(this.effectDict[creative]['effect'])
            }
            return barData
        },
        ifBest() {
            return this.creativeListSorted.indexOf(this.creative) == 0
        },
        init() {
            var fullData = this.$store.getters.fullData
            var creativeFullList = fullData.map(obj => obj.creative)
            var effectFullList = fullData.map(obj => obj.effect)
            this.effectDict = this.calceffect(creativeFullList, effectFullList)
            this.createCreativeListSorted()
            this.overlay = false
        }
    },
    computed: {
        fullData() {
            return this.$store.getters.fullData
        },
    },
    watch: {
        fullData() {
            this.overlay = true
            this.init()
        }
    },
    created(){
        this.init()
    },
})
</script>

<style scoped>
::v-deep .v-select__selections input { width: 0}

.widget-title {
    color: #a7894b;
}

.feature-title {
    font-size: .5em;
    color: gray;
    margin: -2em 0;
    padding: 0;
}

.feature-content {
    font-size: 1em;
    margin-bottom: 1em;
    padding: 0;
}
::v-deep .v-btn {
  display: block !important;
  white-space: normal !important;
  overflow-wrap: anywhere !important;
}
</style>