export const evaluation = {
    methods: {
        evaluation(effect){
            // 4段階評価を行う
            var effectListAbove0 = []
            var effectList = this.$store.getters.fullDataArchive.map(obj => obj.effect)
            for(var e of effectList){
                if(e > 0){
                    effectListAbove0.push(e)
                }
            }

            // 昇順にソートする
            effectListAbove0.sort((a, b) => {
                return a - b
            })

            // 0以下はbad、0より大きい値に関しては3当分して評価する
            var badStandard = 0
            var normalStandard = Math.round(effectListAbove0.length / 3)
            var goodStandard = Math.round(effectListAbove0.length *2/3)
            if (effect <= badStandard){
                return "bad"
            }else if (effect <= effectListAbove0[normalStandard]) {
                return "normal"
            }else if(effect <= effectListAbove0[goodStandard]) {
                return "good"
            }else if(effect > effectListAbove0[goodStandard]) {
                return "great"
            }else{
                return ""
            }
        }
    }
}