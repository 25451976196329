<template>
  <v-app-bar
    app
    clipped-left
    class="v-bar--underline"
    height="45"
    flat
    color="#333c5e"
    elevation="4"
  >

    <v-img
      max-height="25"
      max-width="25"
      src="@/assets/UnitedSquare-removebg.png"
    />&nbsp;

    <v-toolbar-title
      class="text-h5"
      style="overflow: visible;"
    >
    <span class="text-white">UnitedData</span>
    </v-toolbar-title>

    <v-tabs
      right
      color="white"
      v-if="ifAdmin()"
    >
      <v-tab
        :to="{name: 'AdminSelect'}"
      ><span class="text-white">アナリティクス</span>
      </v-tab>
      <v-tab
        :to="{name: 'RegisterSelect'}"
      ><span class="text-white">データ入力</span>
      </v-tab>
    </v-tabs>

    <v-spacer/>

    <Account />

  </v-app-bar>
</template>

<script>
import Account from '@/components/common/modules/generals/Account.vue'

export default {
  name: "Header",
  components: { 
    Account,
  },
  methods: {
    ifAdmin() {
      return this.$store.getters.admin
    },
  },
}
</script>

<style scoped>
.text-white {
  color: white;
}
</style>