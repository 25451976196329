<template>
  <v-app>
    <v-main>
      <v-container fluid class="mt-4">
        <v-card
          class="mx-auto"
          max-width="1000"
        >

        <v-card-title>クリエイティブアップロード</v-card-title>

        <v-container
          style="max-width:600px"
          class="mx-auto"
        >

          <div v-if="preview_url">
            <img
              :src="preview_url"
              height="220px"
            >
          </div>
          <div v-else>
            <div class="preview-box">
            </div>
          </div>
          <v-btn
            class="my-2"
            @click="selectFile"
          >
            画像を選択
          </v-btn>
          <input
              style="display: none" 
              ref="input"
              type="file"
              @change="ImageChanged"
          >
          <div v-if="img_error" class="error">
            画像はpngまたはjpeg形式に変換してください
          </div>

          <v-text-field
            v-model="id"
            label="10桁コード"
            :counter="11"
            dense
            required
          />
          <v-text-field
            v-model="name"
            label="クリエイティブ名"
            required
            dense
          />
          <v-text-field
            v-model="target"
            label="ブランド"
            required
            dense
          />
          <v-text-field
            v-model="length"
            label="放映時間"
            type="number"
            dense
          />

          <div v-if="ongoing">
            <v-btn
              :loading="loading"
              color="#FFCB00"
              class="mb-5 px-8 py-2"
              @click="uploadFile()">
                登録する
            </v-btn>
          </div>

          <div v-else>
            <v-btn
              color="#FFAAAA"
              class="mb-5 px-8 py-2"
              @click="reset()">
                リセット
            </v-btn>
          </div>

          <div v-if="empty_error" class="error">
            空欄があります
          </div>

        </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Storage } from 'aws-amplify'
import axios from "axios"

export default {
  name: 'UploadCreative',
  data() {
    return {
      name: '',
      id: '',
      length: null,
      target: '',
      preview_url: null,
      ongoing: true,
      loading: false,
      endpoint: 'https://7wmgaeletf.execute-api.ap-northeast-1.amazonaws.com/first_stage',
      allowed_extensions: ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG'],
      img_error: false,
      empty_error: false,
    }
  },
  methods:{
    selectFile() {
      if(this.$refs.input != undefined){
        this.$refs.input.click();
      }
    },
    ImageChanged() {
      const file = this.$refs.input.files[0];
      const name = file.name
      const extension = name.substr(name.indexOf('.') + 1)
      if (this.allowed_extensions.includes(extension)) {
        this.preview_url = URL.createObjectURL(file)
        this.img_error = false
      } else {
        this.preview_url = null
        this.img_error = true
      }
    },
    uploadFile() {
      this.loading = true
      let file = this.$refs.input.files[0];
      if(this.validate(file)){
        this.writeDB()
        this.writeS3(file)
        this.loading = false
        this.ongoing = false
        this.empty_error = false
        this.img_error = false
      } else {
        this.loading = false
        this.empty_error = true
      }
    },
    // クリエイティブ画像をS3にアップロードする
    writeS3(file){
      // s3://creativesdisplay01322-dev/public/ に保存される
      Storage.put(this.imageName(), file).then(result => {
        console.log(result);
      }).catch(err => console.log(err))
    },
    // クリエイティブ情報をDynamoDBに書き込む
    writeDB() {
      // RegisterVideoテーブルに書き込むために、API gatewayを通してLambdaにPOSTする      
      axios.post(
        this.endpoint,
        JSON.stringify({
          creative_id: this.id,
          name: this.name,
          length: this.length,
          target: this.target
        }),
        {
          headers:{
          'Content-Type': 'application/json'
          }
        }
      ).then(response => {
        console.log(response.data);
      }).catch(error => console.log(error));
    },
    // 入力項目のチェックを行う
    validate(file) {
      if (file && this.target && this.name && this.id && this.length){
        return true
      } else {
        return false
      }
    },
    imageName() {
      return this.id + '.png'
    },
    reset() {
      this.name = ''
      this.id = ''
      this.length = null
      this.target = ''
      this.preview_url = null
      this.ongoing = true
      this.loading = false
      this.img_error = false
      this.empty_error = false
    }
  },
  created(){
    if(this.$store.getters.user.username != "admin"){
        this.$router.push({ name: "NotFound" })
    }
  },
}
</script>

<style scoped>
.preview-box {
  height: 220px;
  width: 220px;
  border: 2px dotted #ec6d88;
  border-radius: .5em;
  margin: auto;
}
</style>